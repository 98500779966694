import React from 'react'
import { Form, Row, Col } from 'react-bootstrap'

import SelectHomePage from '../widgets/selectHomePage'
import { getFormValue } from '../../helpers/formdata'

const configHomePage = ({ code, data, showSections }) => {
  return (
    <div className="bg-white">
      <h2 className="title">Página de inicio</h2>
      {showSections['homepage'] && (
        <Row>
          <Col sm={8}>
            <Form.Group controlId={`configApp-${code}-homepage`}>
              <Form.Label>Página de inicio</Form.Label>
              <SelectHomePage
                name={`configApp-${code}-homepage`}
                value={getFormValue(data, code, 'homepage')}
              />
            </Form.Group>
          </Col>
        </Row>
      )}
      <hr class="bg-secondary border-2 border-top border-secondary" />
      <div>
        <Row >
          {showSections['whatsappUrl'] && (
            <Col sm={4}>
              <Form.Group controlId={`configApp-${code}-whatsappUrl`}>
                <Form.Label>URL WhatsApp</Form.Label>
                <Form.Control
                  name={`configApp-${code}-whatsappUrl`}
                  defaultValue={getFormValue(data, code, 'whatsappUrl')}
                  type="text"
                />
              </Form.Group>
            </Col>
          )}
          {showSections['whatsappMessage'] && (
            <Col sm={4}>
              <Form.Group controlId={`configApp-${code}-whatsappMessage`}>
                <Form.Label>Mensaje inicial</Form.Label>
                <Form.Control
                  name={`configApp-${code}-whatsappMessage`}
                  defaultValue={getFormValue(data, code, 'whatsappMessage')}
                  type="text"
                />
              </Form.Group>
            </Col>
          )}
        </Row>
        <Row>
          {showSections['whatsappUrlActive'] && (
            <Col sm={4}>
              <Form.Group controlId={`configApp-${code}-whatsappUrlActive`}>
                <Form.Check
                  defaultChecked={getFormValue(data, code, 'whatsappUrlActive')}
                  name={`configApp-${code}-whatsappUrlActive`}
                  type="checkbox"
                  label="Url Whatsapp Activa"
                />
              </Form.Group>
            </Col>
          )}
        </Row>
      </div>
      <hr class="bg-secondary border-2 border-top border-secondary" />
      <div>
        <Row style={{ marginTop: '2rem', }}>
          {showSections['webViewUrl'] && (
            <Col sm={8}>
              <Form.Group controlId={`configApp-${code}-webViewUrl`}>
                <Form.Label>URL WebView ChatBot</Form.Label>
                <Form.Control
                  name={`configApp-${code}-webViewUrl`}
                  defaultValue={getFormValue(data, code, 'webViewUrl')}
                  type="text"
                />
              </Form.Group>
            </Col>
          )}
        </Row>
        <Row>
          {showSections['webViewUrlActive'] && (
            <Col sm={4}>
              <Form.Group controlId={`configApp-${code}-webViewUrlActive`}>
                <Form.Check
                  defaultChecked={getFormValue(data, code, 'webViewUrlActive')}
                  name={`configApp-${code}-webViewUrlActive`}
                  type="checkbox"
                  label="Url Webview Activa"
                />
              </Form.Group>
            </Col>
          )}
        </Row>
      </div>
    </div>
  );
}

export default configHomePage
