import React from 'react'
import { Button, Card, Accordion } from 'react-bootstrap'
import { orderByName } from '../../helpers/utils'
import { getByGroups } from '../../helpers/utils'
import NotificationItem from './notificationItem'

const NotificationList = ({ items, data }) => {
  const notifications = getByGroups(items)
  return (
    <Accordion defaultActiveKey="0">
      {notifications &&
        Object.keys(notifications).map((group, indx) => (
          <Card key={indx}>
          <Accordion.Item eventKey={indx}>
            <Card.Header>
              <Accordion.Button
                as={Button}
                variant="block text-start"
              >
                {group}
              </Accordion.Button>
            </Card.Header>
            <Accordion.Body>
              <Card.Body>
                {notifications[group]['items']
                  .sort(orderByName)
                  .map((item, indx) => (
                    <NotificationItem key={indx} item={item} data={data} />
                  ))}
              </Card.Body>
            </Accordion.Body>
          </Accordion.Item>
          </Card>
        ))}
    </Accordion>
  )
}
export default NotificationList
