import React, { useMemo, useState } from 'react';
import { Modal, Button, Row, Col, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { languages } from '../../data/languages';

const ModalUploadCatalog = ({ id, show, onClose, onLoadCatalog }) => {
  const configApp = useSelector((state) => state.university?.item?.configApp);
  const [listLanguagesLoadCatalog, setListLanguagesLoadCatalog] = useState([]);

  const filteredLanguages = useMemo(() => {
    return configApp?.languages
      ? languages.filter(
          (language) => configApp.languages[language.code] === 'on'
        )
      : [];
  }, [configApp?.languages]);

  const onClickButton = () => {
    onLoadCatalog(listLanguagesLoadCatalog);
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setListLanguagesLoadCatalog((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  return (
    <Modal show={show} size="md" onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title className="ms-auto">Lanzar carga de catálogo</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {filteredLanguages.map((item, indx) => (
          <Row key={indx}>
            <Col sm={6}>
              <p>{item.name}</p>
            </Col>
            <Col sm={2}>
              <Form.Group controlId={`configApp-languages-modal-${item.code}`}>
                <Form.Check
                  name={item.code}
                  className="text-center"
                  type="checkbox"
                  checked={listLanguagesLoadCatalog[item.code] || false}
                  onChange={handleCheckboxChange}
                />
              </Form.Group>
            </Col>
          </Row>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClickButton}>
          Lanzar carga
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalUploadCatalog;
