import React, { useState, useEffect} from 'react'
import { Form } from 'react-bootstrap'

const SelectTimeZone = ({ name, value, items=[] }) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const handleChange = e => {
    setData(Array.from(
      e.target.selectedOptions,
      option => option.value
    ))
  }
  useEffect(() => {
    setLoading(false)
    setData(value)
  }, [value])
  if (loading) {
    return false
  }
  return (
    <Form.Control
      onChange={handleChange}
      value={data}
      name={name}
      as="select"
    >
      {items.map((value, indx) => (
        <option key={indx} value={value}>
          {value}
        </option>
      ))}
    </Form.Control>
  )
}
export default SelectTimeZone