import React from 'react'
import { Form, Row, Col } from 'react-bootstrap'

import { getFormValue } from '../../helpers/formdata'

const ConfigVirtualCard = ({ code, data, showSections }) => {
  return (
    <div className="bg-white">
      <h2 className="title">Tarjeta virtual</h2>
      {showSections['useAlternativeVirtualCard'] && (
        <Row>
          <Col sm={4}>
            <Form.Group controlId={`configApp-${code}-useAlternativeVirtualCard`}>
              <Form.Check
                defaultChecked={getFormValue(data, code, 'useAlternativeVirtualCard')}
                name={`configApp-${code}-useAlternativeVirtualCard`}
                type="checkbox"
                label="Usar configuración de carnet alternativa"
              />
            </Form.Group>
          </Col>
        </Row>
      )}
      {showSections['useEndDateVirtualCard'] && (
        <Row>
          <Col sm={4}>
            <Form.Group controlId={`configApp-${code}-useEndDateVirtualCard`}>
              <Form.Check
                defaultChecked={getFormValue(data, code, 'useEndDateVirtualCard')}
                name={`configApp-${code}-useEndDateVirtualCard`}
                type="checkbox"
                label="Mostrar fecha fin"
              />
            </Form.Group>
          </Col>
        </Row>
      )}
    </div>
  )
}
export default ConfigVirtualCard
