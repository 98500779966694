import React from 'react'
import { Form, Row, Col } from 'react-bootstrap'

import { getFormValue } from '../../helpers/formdata'

const ConfigTS = ({ code, data, showSections }) => {
  return (
    <div className="bg-white">
      <h2 className="title">Título Seguro</h2>
      <Row>
        {showSections['editParams'] && (
          <Col sm={4}>
            <Form.Group controlId={`configApp-${code}-editParams`}>
              <Form.Check
                defaultChecked={getFormValue(data, code, 'editParams')}
                name={`configApp-${code}-editParams`}
                type="checkbox"
                label="Permitir edición de campos del formulario de registro"
              />
            </Form.Group>
          </Col>
        )}
      </Row>
      <Row>
        {showSections['degreeValidationUrl'] && (
          <Col sm={8}>
            <Form.Group controlId={`configApp-${code}-degreeValidationUrl`}>
              <Form.Label>Url de validación de títulos</Form.Label>
              <Form.Control
                name={`configApp-${code}-degreeValidationUrl`}
                defaultValue={getFormValue(data, code, 'degreeValidationUrl')}
                type="text" />
            </Form.Group>
          </Col>
        )}
      </Row>
      <Row>
        {showSections['teacherPeriods'] && (
          <Col sm={8}>
            <Form.Group controlId={`configApp-${code}-teacherPeriods`}>
              <Form.Label>Periodos del docente</Form.Label>
              <Form.Control
                name={`configApp-${code}-teacherPeriods`}
                defaultValue={getFormValue(data, code, 'teacherPeriods')}
                type="text" />
            </Form.Group>
          </Col>
        )}
      </Row>
    </div>
  )
}
export default ConfigTS
