import React, { useRef } from 'react'
import { Form, Modal, Button } from 'react-bootstrap'

const CredentialCreate = ({ id, show, onSubmit, onClose }) => {
  const name = useRef(null)
  const onCreate = () => {
    onSubmit(name.current.value)
  }
  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header>Nueva credencial</Modal.Header>
      <Modal.Body>
        <h3 className="title">
          Introduce un nombre y se generarán las claves automáticamente
        </h3>
        <Form.Group controlId="name">
          <Form.Label>Nombre de la credencial</Form.Label>
          <Form.Control ref={name} type="text" />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Cancelar
        </Button>
        <Button variant="delete" onClick={onCreate}>
          Guardar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
export default CredentialCreate