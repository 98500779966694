import React from 'react'
import { Form, Row, Col } from 'react-bootstrap'

import { getFormValue } from '../../helpers/formdata'

const ConfigScreens = ({ code, data, showSections }) => {
  return (
    <div className="bg-white">
      <h2 className="title">Pantallas de configuración</h2>
      <Row>
        {showSections['widgets'] && (
          <Col sm={12}>
            <Form.Group controlId={`configApp-${code}-widgets`}>
              <Form.Check
                defaultChecked={getFormValue(data, code, 'widgets')}
                name={`configApp-${code}-widgets`}
                type="checkbox"
                label="Configuración de widgets"
              />
            </Form.Group>
          </Col>
        )}
        {showSections['favorites'] && (
          <Col sm={12}>
            <Form.Group controlId={`configApp-${code}-favorites`}>
              <Form.Check
                defaultChecked={getFormValue(data, code, 'favorites')}
                name={`configApp-${code}-favorites`}
                type="checkbox"
                label="Botón de favoritos"
              />
            </Form.Group>
          </Col>
        )}
        {showSections['fav_config'] && (
          <Col sm={12}>
            <Form.Group controlId={`configApp-${code}-fav_config`}>
              <Form.Check
                defaultChecked={getFormValue(data, code, 'fav_config')}
                name={`configApp-${code}-fav_config`}
                type="checkbox"
                label="Configuración de favoritos"
              />
            </Form.Group>
          </Col>
        )}
        {showSections['notifications'] && (
          <Col sm={12}>
            <Form.Group controlId={`configApp-${code}-notifications`}>
              <Form.Check
                defaultChecked={getFormValue(data, code, 'notifications')}
                name={`configApp-${code}-notifications`}
                type="checkbox"
                label="Panel de notificaciones"
              />
            </Form.Group>
          </Col>
          )}
        {showSections['noti_config'] && (
          <Col sm={12}>
            <Form.Group controlId={`configApp-${code}-noti_config`}>
              <Form.Check
                defaultChecked={getFormValue(data, code, 'noti_config')}
                name={`configApp-${code}-noti_config`}
                type="checkbox"
                label="Configuración de notificaciones"
              />
            </Form.Group>
          </Col>
        )}
        {showSections['birthday'] && (
          <Col sm={12}>
            <Form.Group controlId={`configApp-${code}-birthday`}>
              <Form.Check
                defaultChecked={getFormValue(data, code, 'birthday')}
                name={`configApp-${code}-birthday`}
                type="checkbox"
                label="Servicio de cumpleaños"
              />
            </Form.Group>
          </Col>
        )}
      </Row>
    </div>
  )
}
export default ConfigScreens
