export const widgets = [
  { code: "MARKINGS", name: "Marcajes" },
  { code: "ABSENCES_AND_PERMISSIONS", name: "Ausencias y permisos" },
  { code: "DATE_EXAMS", name: "Fechas de examen" },
  { code: "SCHEDULE", name: "Horario" },
  { code: "EVALUATION", name: "Evaluación docente" },
  { code: "ENROLLMENT", name: "Citas de matrícula" },
  { code: "MY_RECEIPTS", name: "Recibos" },
  { code: "PROCEEDINGS", name: "Actas" },
  { code: "MARKS", name: "Notas" },
  { code: "OCCUPANCY", name: "Ocupación de grupos" },
  { code: "DOCUMENTS_DELIVERED_AC", name: "Documentación" },
  { code: "MENU_CMS", name: "Widget menús" },
  { code: "EVENTS", name: "Widget evento" },
  { code: "NEWS", name: "Widget noticias" },
  { code: "NEWS_RSS", name: "Widget noticias RSS" },
  { code: "MEMBERSHIPS", name: "Membresías" },
  { code: "ASSOCIATIONS", name: "Asociaciones" },
  { code: "ABOUT_ALUMNI", name: "Sobre Alumni" },
  { code: "EXPERIENCES", name: "Experiencias" }
]
