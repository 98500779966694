import React from 'react'
import { Form, Row, Col } from 'react-bootstrap'

import { getFormValue } from '../../helpers/formdata'

const ConfigIdentifiers = ({ code, data, showSections }) => {
  return (
    <div className="bg-white">
      <h2 className="title">Nombres e identificadores</h2>
      <Row>
        {showSections['university'] && (
          <Col sm={4}>
            <Form.Group controlId={`configApp-${code}-university`}>
              <Form.Label>University</Form.Label>
              <Form.Control
                name={`configApp-${code}-university`}
                defaultValue={getFormValue(data, code, 'university')}
                type="text" />
            </Form.Group>
          </Col>
        )}
        {showSections['universityShort'] && (
          <Col sm={4}>
            <Form.Group controlId={`configApp-${code}-universityShort`}>
              <Form.Label>University short</Form.Label>
              <Form.Control
                name={`configApp-${code}-universityShort`}
                defaultValue={getFormValue(data, code, 'universityShort')}
                type="text" />
            </Form.Group>
          </Col>
        )}
        {showSections['versionRequired'] && (
          <Col sm={4}>
            <Form.Group controlId={`configApp-${code}-versionRequired`}>
              <Form.Label>Versión mínima requerida</Form.Label>
              <Form.Control
                name={`configApp-${code}-versionRequired`}
                defaultValue={getFormValue(data, code, 'versionRequired')}
                type="text" />
            </Form.Group>
          </Col>
        )}
      </Row>
      <Row>
        {showSections['appleName'] && (
          <Col sm={4}>
            <Form.Group controlId={`configApp-${code}-appleName`}>
              <Form.Label>Apple name</Form.Label>
              <Form.Control
                name={`configApp-${code}-appleName`}
                defaultValue={getFormValue(data, code, 'appleName')}
                type="text" />
            </Form.Group>
          </Col>
        )}
        {showSections['appleId'] && (
          <Col sm={4}>
            <Form.Group controlId={`configApp-${code}-appleId`}>
              <Form.Label>Apple id</Form.Label>
              <Form.Control
                name={`configApp-${code}-appleId`}
                defaultValue={getFormValue(data, code, 'appleId')}
                type="text" />
            </Form.Group>
          </Col>
        )}
      </Row>
    </div>
  )
}
export default ConfigIdentifiers
