import React from 'react'
import { Form, Row, Col } from 'react-bootstrap'

import SelectCountry from '../widgets/selectCountry'
import { getFormValue } from '../../helpers/formdata'

const configPublicAccessConf = ({ code, data, showSections }) => {
  return (
    <div className="bg-white">
      <h2 className="title">Configuración de acceso público</h2>
      <Row>
        {showSections['phoneCountry'] && (
          <Col sm={4}>
            <Form.Group controlId={`configApp-${code}-phoneCountry`}>
              <Form.Label>País del teléfono</Form.Label>
              <SelectCountry
                name={`configApp-${code}-phoneCountry`}
                value={getFormValue(data, code, 'phoneCountry')} />
            </Form.Group>
          </Col>
        )}
      </Row>
      <Row>
        {showSections['showCommunityHelp'] && (
          <Col sm={4}>
            <Form.Group controlId={`configApp-${code}-showCommunityHelp`}>
              <Form.Check
                defaultChecked={getFormValue(data, code, 'showCommunityHelp')}
                name={`configApp-${code}-showCommunityHelp`}
                type="checkbox"
                label="Mostrar botón 'Te ayudamos'"
              />
            </Form.Group>
          </Col>
        )}
      </Row>
    </div>
  )
}
export default configPublicAccessConf
