import React, { useState, useEffect } from 'react'
import { Accordion } from 'react-bootstrap'
import { getByGroups } from '../../helpers/utils'
import GroupCard from './groupCard'

const GroupList = ({ identity, items, data }) => {
  const [groups, setGroups] = useState({})
  useEffect(() => {
    setGroups(getByGroups(items))
  }, [setGroups, items])
  return (
    <Accordion defaultActiveKey="0">
      {groups && Object.keys(groups).map((group, indx) => (
        <GroupCard
          key={indx}
          group={group}
          data={data}
          identity={identity}
          code={groups[group]['code']}
          items={groups[group]['items']}
        />
      ))}
    </Accordion>
  )
}
export default GroupList