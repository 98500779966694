import { sectionUpdateAllowed } from '../data/permissions'
import { endpointUpdateNotAllowed } from '../data/permissions'

export const getFormValue = (data, entity, key, defaultValue='') => {
  try {
    return (data[entity][key] || defaultValue)
  } catch(err) {
    return defaultValue
  }
}
export const getDataValue = (key, section, code, value, baseUrl) => {
  if (!value || baseUrl === null || key !== 'url') {
    return value
  }
  if (!sectionUpdateAllowed.includes(section)) {
    return value
  }
  if (endpointUpdateNotAllowed.includes(code)) {
    return value
  }
  return getUrlValue(baseUrl, value)
}
export const getTypeValue = target => {
  if (target.type === 'checkbox') {
    return target.checked
  }
  return target.value || ''
}
export const removeSlash = value => {
  while (value.endsWith('/')) {
    value = value.slice(0, -1)
  }
  return value
}
export const getUrlValue = (baseUrl, value) => {
  const urlRegex = /^http(s)?:\/\/[^#?/]+/
  const baseRoot = removeSlash(baseUrl)
  return value.replace(urlRegex, baseRoot)
}
export const getSubentityFormValue = (data, entity, subEntity, key, defaultValue='') => {
  try {
    return data[entity][subEntity][key]
  } catch(err) {
    return defaultValue
  }
}
export const getFormObject = (keys, section, code, value, baseUrl, data) => {
  const key = keys.shift()
  if (keys.length) {
    if (key in data === false) {
      data[key] = {}
    }
    getFormObject(keys, section, code, value, baseUrl, data[key])
  } else {
    data[key] = getDataValue(key, section, code, value, baseUrl)
  }
}
export const getFormArray = (keys, value, data) => {
  const key = keys.shift()
  if (keys.length) {
    if (key in data === false) {
      data[key] = {}
    }
    getFormArray(keys, value, data[key])
  } else {
    if (key in data === false) {
      data[key] = []
    }
    data[key].push(value)
  }
}
export const getFormData = (target, url, data, separator='-') => {
  const formData = new FormData(target)
  const entries = formData.entries()
  for (const [key, value] of entries) {
    const keys = key.split(separator)
    if (key.includes('authType')) {
      getFormArray(keys, value, data)
    } else if (key.includes('roleuc')) {
      getFormArray(keys, value, data)
    } else if (!key.includes('appcode')) {
      getFormObject(keys, keys[0], keys[1], value, url, data)
    }
  }
  return data
}
export const getExportData = (target, data, separator='-') => {
  const formData = new FormData(target)
  const entries = formData.entries()
  for (const [key] of entries) {
    const keys = key.split(separator)
    if (key.includes('appcode') && !data.includes(keys[1])) {
      data.push(keys[1])
    }
  }
  return data
}
export const getListData = (data, prop) => {
  const entries = []
  if (data.hasOwnProperty(prop)) {
    for (const key of Object.keys(data[prop])) {
      entries.push(key.toUpperCase())
    } 
  }
  return entries
}
export const getConfigData = (target, data) => {
  const formData = new FormData(target)
  const entries = formData.entries()
  for (const [key, value] of entries) {
    data[key] = value
  }
  return data
}
export const getConfigUpdated = (target, data, separator='-') => {
  const levels = target.name.split(separator)
  const value = getTypeValue(target)
  var result = data, current
  levels.shift()
  while (levels.length > 0) {
    current = levels.shift()
    if (!(current in result)) result[current] = {}
    if (!(levels.length)) result[current] = value
    result = result[current]
  }
  return result
}