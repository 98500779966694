import React from 'react'
import { Form, Row, Col } from 'react-bootstrap'

import { getFormValue } from '../../helpers/formdata'

const configPublicAccessAuth = ({ code, data, showSections }) => {
  return (
    <div className="bg-white">
      <h2 className="title">Credenciales de acceso público</h2>
      <h2>Nombres de usuario</h2>
      <Row>
        {showSections['aspirantUserName'] && (
          <Col sm={4}>
            <Form.Group controlId={`configApp-${code}-aspirantUserName`}>
              <Form.Label>Nombre de usuario de aspirante</Form.Label>
              <Form.Control
                name={`configApp-${code}-aspirantUserName`}
                defaultValue={getFormValue(data, code, 'aspirantUserName')}
                type="text" />
            </Form.Group>
          </Col>
        )}
        {showSections['over50UserName'] && (
          <Col sm={4}>
            <Form.Group controlId={`configApp-${code}-over50UserName`}>
              <Form.Label>Nombre de usuario mayores de 50</Form.Label>
              <Form.Control
                name={`configApp-${code}-over50UserName`}
                defaultValue={getFormValue(data, code, 'over50UserName')}
                type="text" />
            </Form.Group>
          </Col>
        )}
        {showSections['studentUserName'] && (
          <Col sm={4}>
            <Form.Group controlId={`configApp-${code}-studentUserName`}>
              <Form.Label>Nombre de usuario Alumni</Form.Label>
              <Form.Control
                name={`configApp-${code}-studentUserName`}
                defaultValue={getFormValue(data, code, 'studentUserName')}
                type="text" />
            </Form.Group>
          </Col>
        )}
      </Row>
      <Row>
        {showSections['communityUserName'] && (
          <Col sm={4}>
            <Form.Group controlId={`configApp-${code}-communityUserName`}>
              <Form.Label>Nombre de usuario de Comunidad</Form.Label>
              <Form.Control
                name={`configApp-${code}-communityUserName`}
                defaultValue={getFormValue(data, code, 'communityUserName')}
                type="text" />
            </Form.Group>
          </Col>
        )}
        {showSections['guestUserName'] && (
          <Col sm={4}>
            <Form.Group controlId={`configApp-${code}-guestUserName`}>
              <Form.Label>Nombre de usuario de invitado</Form.Label>
              <Form.Control
                name={`configApp-${code}-guestUserName`}
                defaultValue={getFormValue(data, code, 'guestUserName')}
                type="text" />
            </Form.Group>
          </Col>
        )}
      </Row>
      <Row>
        {showSections['password'] && (
          <Col sm={8}>
            <Form.Group controlId={`configApp-${code}-password`}>
              <Form.Label>Contraseña acceso público</Form.Label>
              <Form.Control
                name={`configApp-${code}-password`}
                defaultValue={getFormValue(data, code, 'password')}
                type="text" />
            </Form.Group>
          </Col>
        )}
      </Row>
    </div>
  )
}
export default configPublicAccessAuth
