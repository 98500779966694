import React from 'react'
import { Row, Col, ListGroup, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { orderByName } from '../../helpers/utils'
import { isAdminRole } from '../../helpers/roles'

const UniversityList = ({ items, onCreate }) => (
  <Row className="justify-content-md-center">
    { isAdminRole() && (
    <Col md="12">
      <Button variant="link" className="float-end" onClick={onCreate}>
        Añadir
      </Button>
    </Col>)}
    <Col md="12">
      <ListGroup>
        {items.sort(orderByName).map((item, indx) => (
          <div key={indx} className="list-group-container">
            <ListGroup.Item
              to={`/universities/${item.universityId}`}
              as={Link}
              action
            >
              {item.name}
            </ListGroup.Item>
          </div>
        ))}
      </ListGroup>
    </Col>
  </Row>
)
export default UniversityList
