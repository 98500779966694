export const AUTHENTICATED = 'AUTHENTICATED'
export const UNAUTHENTICATED = 'UNAUTHENTICATED'
export const AUTHENTICATION_ERROR = 'AUTHENTICATION_ERROR'
export const FETCH_UNIVERSITY_BEGIN = 'FETCH_UNIVERSITY_BEGIN'
export const FETCH_UNIVERSITY_LIST = 'FETCH_UNIVERSITY_LIST'
export const FETCH_UNIVERSITY_ITEM = 'FETCH_UNIVERSITY_ITEM'
export const FETCH_UNIVERSITY_FAILURE = 'FETCH_UNIVERSITY_FAILURE'
export const FETCH_CREDENTIAL_BEGIN = 'FETCH_CREDENTIAL_BEGIN'
export const FETCH_CREDENTIAL_LIST = 'FETCH_CREDENTIAL_LIST'
export const FETCH_CREDENTIAL_FAILURE = 'FETCH_CREDENTIAL_FAILURE'
export const FETCH_VERSIONS = 'FETCH_VERSIONS'
export const FETCH_VERSIONS_FAILURE = 'FETCH_VERSIONS_FAILURE'
