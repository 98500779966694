import React, { useState, useEffect} from 'react'
import { Form } from 'react-bootstrap'

const SelectIdentity = ({ name, value }) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const handleChange = e => {
    setData(Array.from(
      e.target.selectedOptions,
      option => option.value
    ))
  }
  useEffect(() => {
    setLoading(false)
    setData(value)
  }, [value])
  if (loading) {
    return false
  }
  return (
    <Form.Control
      onChange={handleChange}
      value={data}
      name={name}
      as="select"
    >
      <option value="idgriu">IDGRIU</option>
      <option value="idts">IDTS</option>
      <option value="idndi">IDNDI</option>
      <option value="preferred_username">CUENTA</option>
    </Form.Control>
  )
}
export default SelectIdentity