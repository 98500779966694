import React from 'react';
import { Navigate } from 'react-router-dom';
import { LayoutBase } from '../layouts';

const Home = () => (
  <LayoutBase>
    <Navigate exact from="/" to="/universities" />
  </LayoutBase>
);

export default Home;
