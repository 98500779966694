import React from 'react'
import { Form, Row, Col } from 'react-bootstrap'

import SelectDownload from '../widgets/selectDownload'
import { getFormValue } from '../../helpers/formdata'

const ConfigDownload = ({ code, data, showSections }) => {
  return (
    <div className="bg-white">
      <h2 className="title">Descargas</h2>
      <Row>
        {showSections['certificates'] && (
          <Col sm={12}>
            <Form.Group controlId={`configApp-${code}-certificates`} className="inline">
              <Form.Check
                defaultChecked={getFormValue(data, code, 'certificates')}
                name={`configApp-${code}-certificates`}
                type="checkbox"
                label="Descarga de certificados academicos"
              />
            </Form.Group>
            <Form.Group controlId={`configApp-${code}Types-certificates`} className="inline">
              <SelectDownload
                name={`configApp-${code}Types-certificates`}
                value={getFormValue(data, `${code}Types`, 'certificates')}
              />
            </Form.Group>
          </Col>
        )}
        {showSections['receipts'] && (
          <Col sm={12}>
            <Form.Group controlId={`configApp-${code}-receipts`} className="inline">
              <Form.Check
                defaultChecked={getFormValue(data, code, 'receipts')}
                name={`configApp-${code}-receipts`}
                type="checkbox"
                label="Descarga de justificantes de matricula de académico"
              />
            </Form.Group>
            <Form.Group controlId={`configApp-${code}Types-receipts`} className="inline">
              <SelectDownload
                name={`configApp-${code}Types-receipts`}
                value={getFormValue(data, `${code}Types`, 'receipts')}
              />
            </Form.Group>
          </Col>
        )}
        {showSections['withholdings'] && (
          <Col sm={12}>
            <Form.Group controlId={`configApp-${code}-withholdings`} className="inline">
              <Form.Check
                defaultChecked={getFormValue(data, code, 'withholdings')}
                name={`configApp-${code}-withholdings`}
                type="checkbox"
                label="Certificados de retenciones de rrhh"
              />
            </Form.Group>
            <Form.Group controlId={`configApp-${code}Types-withholdings`} className="inline">
              <SelectDownload
                name={`configApp-${code}Types-withholdings`}
                value={getFormValue(data, `${code}Types`, 'withholdings')}
              />
            </Form.Group>
          </Col>
        )}
        {showSections['rrhh_cv'] && (
          <Col sm={12}>
            <Form.Group controlId={`configApp-${code}-rrhh_cv`} className="inline">
              <Form.Check
                defaultChecked={getFormValue(data, code, 'rrhh_cv')}
                name={`configApp-${code}-rrhh_cv`}
                type="checkbox"
                label="Currículum de rrhh"
              />
            </Form.Group>
            <Form.Group controlId={`configApp-${code}Types-rrhh_cv`} className="inline">
              <SelectDownload
                name={`configApp-${code}Types-rrhh_cv`}
                value={getFormValue(data, `${code}Types`, 'rrhh_cv')}
              />
            </Form.Group>
          </Col>
        )}
        {showSections['service_sheets'] && (
          <Col sm={12}>
            <Form.Group controlId={`configApp-${code}-service_sheets`} className="inline">
              <Form.Check
                defaultChecked={getFormValue(data, code, 'service_sheets')}
                name={`configApp-${code}-service_sheets`}
                type="checkbox"
                label="Hojas de servicio de rrhh"
              />
            </Form.Group>
            <Form.Group controlId={`configApp-${code}Types-service_sheets`} className="inline">
              <SelectDownload
                name={`configApp-${code}Types-service_sheets`}
                value={getFormValue(data, `${code}Types`, 'service_sheets')}
              />
            </Form.Group>
          </Col>
        )}
        {showSections['res_cv'] && (
          <Col sm={12}>
            <Form.Group controlId={`configApp-${code}-res_cv`} className="inline">
              <Form.Check
                defaultChecked={getFormValue(data, code, 'res_cv')}
                name={`configApp-${code}-res_cv`}
                type="checkbox"
                label="Descarga de Currículum de investigación"
              />
            </Form.Group>
            <Form.Group controlId={`configApp-${code}Types-res_cv`} className="inline">
              <SelectDownload
                name={`configApp-${code}Types-res_cv`}
                value={getFormValue(data, `${code}Types`, 'res_cv')}
              />
            </Form.Group>
          </Col>
        )}
        {showSections['economic_situation'] && (
          <Col sm={12}>
            <Form.Group controlId={`configApp-${code}-economic_situation`} className="inline">
              <Form.Check
                defaultChecked={getFormValue(data, code, 'economic_situation')}
                name={`configApp-${code}-economic_situation`}
                type="checkbox"
                label="Informes de situación económica"
              />
            </Form.Group>
            <Form.Group controlId={`configApp-${code}Types-economic_situation`} className="inline">
              <SelectDownload
                name={`configApp-${code}Types-economic_situation`}
                value={getFormValue(data, `${code}Types`, 'economic_situation')}
              />
            </Form.Group>
          </Col>
        )}
        {showSections['published_memoirs'] && (
          <Col sm={12}>
            <Form.Group controlId={`configApp-${code}-published_memoirs`} className="inline">
              <Form.Check
                defaultChecked={getFormValue(data, code, 'published_memoirs')}
                name={`configApp-${code}-published_memoirs`}
                type="checkbox"
                label="Memorias de investigación"
              />
            </Form.Group>
            <Form.Group controlId={`configApp-${code}Types-published_memoirs`} className="inline">
              <SelectDownload
                name={`configApp-${code}Types-published_memoirs`}
                value={getFormValue(data, `${code}Types`, 'published_memoirs')}
              />
            </Form.Group>
          </Col>
        )}
      </Row>
    </div>
  )
}
export default ConfigDownload
