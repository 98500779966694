export const languages = [
  { code: 'es', name: 'Español España' },
  { code: 'es_co', name: 'Español América' },
  { code: 'ca', name: 'Catalán' },
  { code: 'gl', name: 'Gallego' },
  { code: 'eu', name: 'Euskera' },
  { code: 'en', name: 'Inglés' },
  { code: 'fr', name: 'Francés' },
  { code: 'va', name: 'Valenciano' },
];
