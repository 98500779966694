import React, { useState } from 'react'
import { Form, InputGroup, Button, Card, Accordion } from 'react-bootstrap'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import { isAdminRole } from '../../helpers/roles'

const CredentialItem = ({ identity, data, onDelete }) => {
  const { name, credentialId, secretId} = data
  const code = `${identity}-${credentialId}`
  const [showCredential, setShowCredential] = useState(false)
  const [showSecret, setShowSecret] = useState(false)

  return (
    <Card>
      <Accordion.Item eventKey={code}>
       <Card.Header>
        <Accordion.Button
           as={Button}
           variant="block text-start"
         >
           {name}
         </Accordion.Button>
       </Card.Header>
      <Accordion.Body>
        <Card.Body>
          <Form.Group controlId="credentialId">
            <Form.Label>Credencial</Form.Label>
            <InputGroup>
              <Form.Control
                name=""
                type={showCredential ? "text" : "password"}
                value={credentialId}
                readOnly
              />
              {isAdminRole() && (
              <Button variant="eye" onClick={() => setShowCredential(!showCredential)}>
                {showCredential ? <FaEyeSlash /> : <FaEye />}
              </Button>)}
            </InputGroup>
          </Form.Group>
          <Form.Group controlId="secretKey">
            <Form.Label>Secreto</Form.Label>
            <InputGroup>
              <Form.Control
                name=""
                type={showSecret ? "text" : "password"}
                value={secretId}
                readOnly
              />
              {isAdminRole() && (
              <Button variant="eye" onClick={() => setShowSecret(!showSecret)}>
                {showSecret ? <FaEyeSlash /> : <FaEye />}
              </Button>)}
            </InputGroup>
          </Form.Group>
          {isAdminRole() && (
          <Button
            type="button"
            className="btn-remove float-end"
            onClick={() => onDelete(credentialId)}
          >
            Eliminar credencial
          </Button>)}
        </Card.Body>
      </Accordion.Body>
    </Accordion.Item>
    </Card>
  )
}
export default CredentialItem
