import React, { useState } from 'react'
import { Modal, Form, Button } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'

import { isValidUuid } from '../../helpers/utils'

const ModalDuplicate = ({ id, show, onConfirm, onClose }) => {
  const [generateApiKeys, setGenerateApiKeys] = useState(true)
  const items = useSelector((state) => state.university.items)
  // const dispatch = useDispatch() Hacer sincrona la llamada

  const [formData, setFormData] = useState({
    universityId: '',
    secretKey: ''
  })
  const [errors, setErrors] = useState({})

  const handleClose = () => {
    setGenerateApiKeys(true)
    setFormData({
      universityId: '',
      secretKey: ''
    })
    setErrors({})
    onClose()
  }
  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData({
        ...formData,
        [name]: value
    })
  }
  const handleConfirm = (e) => {
    e.preventDefault()
    const newErrors = validateForm(formData)
    setErrors(newErrors)

    if (Object.keys(newErrors).length === 0) {
      const data = {
        generateApiKeys: generateApiKeys,
        universityId: formData.universityId,
        secretKey: formData.secretKey
      }
      onConfirm(data)
    }
  }
  const validateForm = (data) => {
    const errors = {}
    if (!generateApiKeys) {
      if(!isValidUuid(data.universityId)) {
        errors.universityId = 'La clave de la universidad debe ser un UUID válido'
      } else {
        // dispatch(fetchUniversities()) hacerlo sincrono
        if (items.some((item) => item.universityId === data.universityId)) {
          errors.universityId = 'La clave de la universidad introducida ya existe'
        }
      }
    }
    if (!generateApiKeys) {
      if(!isValidUuid(data.secretKey)) {
        errors.secretKey = 'El secreto de la universidad debe ser un UUID válido'
      } else {
        // dispatch(fetchUniversities()) hacerlo sincrono
        if (items.some((item) => item.secretKey === data.secretKey)) {
          errors.secretKey = 'El secreto de la universidad introducida ya existe'
        }
      }
    }
    return errors
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Body>
        <Form.Group controlId="generateApiKeys">
          <Form.Check
            name="generateApiKeys"
            label="Generar claves API automáticamente"
            defaultChecked={generateApiKeys}
            type="checkbox"
            onClick={(e) => {
              if (e.target.checked) {
                setGenerateApiKeys(true)
              } else {
                setGenerateApiKeys(false)
              }
            }}
          />
        </Form.Group>
        {!generateApiKeys && (<>
          <Form.Group controlId="universityId">
            <Form.Label>Clave</Form.Label>
            <Form.Control
              name="universityId"
              type="text"
              value={formData.universityId}
              onChange={handleChange}
              readOnly={generateApiKeys}
            />
            {errors.universityId &&
              <span className="error-message">
                {errors.universityId}
              </span>
            }
          </Form.Group>
          <Form.Group controlId="secretKey">
            <Form.Label>Secreto</Form.Label>
            <Form.Control
              name="secretKey"
              type="text"
              value={formData.secretKey}
              onChange={handleChange}
              readOnly={generateApiKeys}
            />
            {errors.secretKey &&
              <span className="error-message">
                {errors.secretKey}
              </span>
            }
          </Form.Group>
        </>)}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancelar
        </Button>
        <Button variant="delete" onClick={handleConfirm}>
          Duplicar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
export default ModalDuplicate
