import React, { useState, useEffect} from 'react'
import { Form } from 'react-bootstrap'

const SelectAuth = ({ name, value }) => {
  const [data, setData] = useState([])
  const handleChange = e => {
    setData(Array.from(
      e.target.selectedOptions,
      option => option.value
    ))
  }
  useEffect(() => {
    setData(value)
  }, [value])
  return (
    <Form.Control
      onChange={handleChange}
      value={data}
      name={name}
      as="select"
      multiple
    >
      <option value="debug">DEBUG</option>
      <option value="guest">INVITADO</option>
      <option value="ldap">LDAP</option>
      <option value="saml">SAML</option>
      <option value="saml_cognito">SAML / COGNITO</option>
      <option value="oauth">OAUTH</option>
      <option value="jwe_crue">JWE CRUE</option>
      <option value="ts">TÍTULO SEGURO</option>
      <option value="ldap_ts">LDAP - TÍTULO SEGURO</option>
      <option value="saml_ts">SAML - TÍTULO SEGURO</option>
      <option value="saml_cognito_ts">SAML / COGNITO - TÍTULO SEGURO</option>
      <option value="oauth_ts">OAUTH - TÍTULO SEGURO</option>
      <option value="jwe_crue_ts">JWE CRUE - TÍTULO SEGURO</option>
    </Form.Control>
  )
}
export default SelectAuth
