import React from 'react'
import { Form, Row, Col } from 'react-bootstrap'

import { getFormValue } from '../../helpers/formdata'

const configPayment = ({ code, data, showSections }) => {
  return (
    <div className="bg-white">
      <h2 className="title">Pasarela de pago Alumni</h2>
      <Row>
        {showSections['url'] && (
          <Col sm={8}>
            <Form.Group controlId={`configApp-${code}-url`}>
              <Form.Label>URL pasarela</Form.Label>
              <Form.Control
                name={`configApp-${code}-url`}
                defaultValue={getFormValue(data, code, 'url')}
                type="text" />
            </Form.Group>
          </Col>
        )}
      </Row>
    </div>
  )
}
export default configPayment
