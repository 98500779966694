import React from 'react'
import { Form, Button, Row, Col, Card, Accordion } from 'react-bootstrap'
import { getFormValue } from '../../helpers/formdata'

const AuthenticationSaml = ({ id, data }) => (
  <Card>
    <Accordion.Item eventKey={id}>
      <Card.Header>
        <Accordion.Button as={Button} variant="block text-start">
          SAML
        </Accordion.Button>
      </Card.Header>
      <Accordion.Body>
        <Card.Body>
          <Form.Group controlId={`authentication-saml-idp`}>
            <Form.Label>IdP</Form.Label>
            <Form.Control
              name={`authentication-saml-idp`}
              defaultValue={getFormValue(data, 'saml', 'idp')}
              type="text"
            />
          </Form.Group>
          <Form.Group controlId={`authentication-saml-appDomain`}>
            <Form.Label>App domain</Form.Label>
            <Form.Control
              name={`authentication-saml-appDomain`}
              defaultValue={getFormValue(data, 'saml', 'appDomain')}
              type="text"
            />
          </Form.Group>
          <Row>
            <Col>
              <Form.Group controlId={`authentication-saml-redirectSignIn`}>
                <Form.Label>Redirección inicio de sessión</Form.Label>
                <Form.Control
                  name={`authentication-saml-redirectSignIn`}
                  defaultValue={getFormValue(data, 'saml', 'redirectSignIn')}
                  type="text"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId={`authentication-saml-redirectSignOuth`}>
                <Form.Label>Redirección cierre de sessión</Form.Label>
                <Form.Control
                  name={`authentication-saml-redirectSignOuth`}
                  defaultValue={getFormValue(data, 'saml', 'redirectSignOuth')}
                  type="text"
                />
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Accordion.Body>
    </Accordion.Item >
  </Card>
)
export default AuthenticationSaml
