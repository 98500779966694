import React from 'react'
import { Form, Button, Card, Accordion } from 'react-bootstrap'

const AuthenticationCrue = ({ id, cruePrivateKey, crueUserMap }) => (
  <Card>
    <Accordion.Item eventKey={id}>
      <Card.Header>
        <Accordion.Button as={Button} variant="block text-start">
          CRUE
        </Accordion.Button>
      </Card.Header>
      <Accordion.Body>
        <Card.Body>
          <Form.Group controlId="cruePrivateKey">
            <Form.Label>Clave privada</Form.Label>
            <Form.Control
              name="cruePrivateKey"
              as="textarea"
              rows={3}
              defaultValue={cruePrivateKey}
            />
          </Form.Group>
          <Form.Group controlId="crueUserMap">
            <Form.Label>Mapeo de usuario</Form.Label>
            <Form.Control
              name="crueUserMap"
              type="text"
              defaultValue={crueUserMap}
            />
          </Form.Group>
        </Card.Body>
      </Accordion.Body>
    </Accordion.Item >
  </Card>
)
export default AuthenticationCrue