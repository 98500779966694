import React, { useState } from 'react'
import { Form, InputGroup, Row, Col, Button } from 'react-bootstrap'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import { getFormValue } from '../../helpers/formdata'
import { isAdminRole } from '../../helpers/roles'

const IdentityBase = ({ code, identity, label, children }) => {
  const [showUser, setShowUser] = useState(false)
  const [showPassword, setShowPassword] = useState(false)

  return (<>
    {code === 'provisioning' && (
      <Row>
        <Col>
          <Form.Group className="check-group">
            <Form.Check
                id={`identity-${code}-enabled`}
                defaultChecked={getFormValue(identity, code, 'enabled')}
                name={`identity-${code}-enabled`}
                label="Activo"
                type="checkbox"
              />
          </Form.Group>
        </Col>
      </Row>
    )}
    <Row>
      <Col>
        <Form.Group controlId={`identity-${code}-username`}>
          <Form.Label>Usuario</Form.Label>
          <InputGroup>
            <Form.Control
              name={`identity-${code}-username`}
              defaultValue={getFormValue(identity, code, 'username')}
              type={showUser ? "text" : "password"}
              readOnly={!isAdminRole()}
            />
            {isAdminRole() && (
            <Button variant="eye" onClick={() => setShowUser(!showUser)}>
              {showUser ? <FaEyeSlash /> : <FaEye />}
            </Button>)}
          </InputGroup>
        </Form.Group>
      </Col>
      <Col>
        <Form.Group controlId={`identity-${code}-password`}>
          <Form.Label>Contraseña</Form.Label>
          <InputGroup>
            <Form.Control
              name={`identity-${code}-password`}
              defaultValue={getFormValue(identity, code, 'password')}
              type={showPassword ? "text" : "password"}
              readOnly={!isAdminRole()}
            />
            {isAdminRole() && (
            <Button variant="eye" onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </Button>)}
          </InputGroup>
        </Form.Group>
      </Col>
    </Row>
    <Form.Group controlId={`identity-${code}-urlToken`}>
      <Form.Label>URL Token</Form.Label>
      <Form.Control
        name={`identity-${code}-urlToken`}
        defaultValue={getFormValue(identity, code, 'urlToken')}
        type="text"
      />
    </Form.Group>
    <Form.Group controlId={`identity-${code}-urlModule`}>
      <Form.Label>URL Módulo</Form.Label>
      <Form.Control
        name={`identity-${code}-urlModule`}
        defaultValue={getFormValue(identity, code, 'urlModule')}
        type="text"
      />
    </Form.Group>
    <Form.Group controlId={`identity-${code}-idModule`}>
      <Form.Label>ID Módulo</Form.Label>
      <Form.Control
        name={`identity-${code}-idModule`}
        defaultValue={getFormValue(identity, code, 'idModule')}
        type="text"
      />
    </Form.Group>
    <Form.Group controlId={`identity-${code}-token`} style={{display: "none"}}>
      <Form.Label>Token</Form.Label>
      <Form.Control
        name={`identity-${code}-token`}
        defaultValue={getFormValue(identity, code, 'token')}
        type="text"
        readOnly
      />
    </Form.Group>
    {children}
  </>)
}
export default IdentityBase
