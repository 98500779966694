import lscache from 'lscache'
import { slugify } from './utils'

export const getCache = key => {
  const bucket = slugify(process.env.REACT_APP_NAME)
  lscache.setBucket(bucket)
  return lscache.get(key)
}
export const setCache = (key, value) => {
  const bucket = slugify(process.env.REACT_APP_NAME)
  lscache.setBucket(bucket)
  lscache.set(key, value)
}
export const removeCache = key => {
  const bucket = slugify(process.env.REACT_APP_NAME)
  lscache.setBucket(bucket)
  lscache.remove(key)
}
export const flushCache = () => {
  const bucket = slugify(process.env.REACT_APP_NAME)
  lscache.setBucket(bucket)
  lscache.flush()
}
