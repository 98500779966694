import React, { useState, useEffect, createRef } from 'react'
import { Form, Modal, Button } from 'react-bootstrap'
import { getFormValue } from '../../helpers/formdata'

const ModalUCMap = ({ title, code, data, show, onClose}) => {
  const [items, setItems] = useState([])
  const handleChange = (e, indx) => {
    const item = items[indx]
    const value = e.target.value
    item.current.value = value
  }
  const getDefaultValue = (indx) => {
    const item = items[indx]
    if (item.current && item.current.value) {
      return item.current.value
    }
    return getFormValue(data, code, `map${indx+1}`)
  }
  const createItems = (iters) => {
    const items = []
    iters.forEach(() => {
      items.push(createRef())
    })
    setItems(items)
  }
  useEffect(() => {
    createItems([...Array(5)])
  }, [])
  return (
    <div className="modal-group">
      <Modal show={show} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Mapeo de atributos - {title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {items && items.map((item, indx) => (
            <Form.Group key={indx} controlId={`general-${code}-map${indx+1}`}>
              <Form.Label>Línea {indx+1}</Form.Label>
              <Form.Control
                name={`general-${code}-map${indx+1}`}
                defaultValue={getDefaultValue(indx)}
                onChange={(e) => handleChange(e, indx)}
                type="text"
              />
            </Form.Group>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={onClose}>
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>
      {items && items.map((item, indx) => (
        <Form.Group key={indx} className="modal-hide">
          <Form.Control
            ref={item}
            name={`general-${code}-map${indx+1}`}
            defaultValue={getFormValue(data, code, `map${indx+1}`)}
            type="text"
          />
        </Form.Group>
      ))}
    </div>
  )
}
export default ModalUCMap