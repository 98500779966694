import React from 'react'
import { Form, Row, Col } from 'react-bootstrap'

import { getFormValue } from '../../helpers/formdata'

const ConfigEnrollment = ({ code, data, showSections }) => {
  return (
    <div className="bg-white">
      <h2 className="title">Citas de matrícula</h2>
      <Row>
        {showSections['showEnrollmentButton'] && (
          <Col sm={4}>
            <Form.Group controlId={`configApp-${code}-showEnrollmentButton`}>
              <Form.Check
                defaultChecked={getFormValue(data, code, 'showEnrollmentButton')}
                name={`configApp-${code}-showEnrollmentButton`}
                type="checkbox"
                label="Mostrar enlace de citas de matrícula"
              />
            </Form.Group>
          </Col>
        )}
      </Row>
    </div>
  )
}
export default ConfigEnrollment
