import React from 'react'
import { Form, Row, Col } from 'react-bootstrap'

import SelectLogin from '../widgets/selectLogin'
import { getFormValue } from '../../helpers/formdata'
import { getSubentityFormValue } from '../../helpers/formdata'


const ConfigLogin = ({ code, data, showSections }) => {
  return (
    <div className="bg-white">
      <h2 className="title">Login University</h2>
      <Row>
        {showSections['loginType'] && (
          <Col sm={4}>
            <Form.Group controlId={`configApp-${code}-loginType`}>
              <Form.Label>Tipo de login</Form.Label>
              <SelectLogin
                name={`configApp-${code}-loginType`}
                value={getFormValue(data, code, 'loginType')} />
            </Form.Group>
          </Col>
        )}
      </Row>
      <Row>
        {showSections['loginTs'] && (
          <Col sm={4}>
            <Form.Group controlId={`configApp-${code}-loginTs`}>
              <Form.Check
                defaultChecked={getFormValue(data, code, 'loginTs')}
                name={`configApp-${code}-loginTs`}
                type="checkbox"
                label="Login Título seguro"
              />
            </Form.Group>
          </Col>
        )}
      </Row>
      <Row>
        {showSections['loginSaaS'] && (
          <Col sm={4}>
            <Form.Group controlId={`configApp-${code}-loginSaaS`}>
              <Form.Check
                defaultChecked={getFormValue(data, code, 'loginSaaS')}
                name={`configApp-${code}-loginSaaS`}
                type="checkbox"
                label="Login SaaS"
              />
            </Form.Group>
          </Col>
        )}
      </Row>
      <Row>
        {showSections['extraPublicAccess'] && (
          <Col sm={4}>
            <h2>Acceso público (extra)</h2>
            <Form.Group controlId={`configApp-${code}-extraPublicAccess-aspirants`}>
              <Form.Check
                defaultChecked={getSubentityFormValue(data, code, 'extraPublicAccess', 'aspirants')}
                name={`configApp-${code}-extraPublicAccess-aspirants`}
                type="checkbox"
                label="Aspirants"
              />
            </Form.Group>
            <Form.Group controlId={`configApp-${code}-extraPublicAccess-over50`}>
              <Form.Check
                defaultChecked={getSubentityFormValue(data, code, 'extraPublicAccess', 'over50')}
                name={`configApp-${code}-extraPublicAccess-over50`}
                type="checkbox"
                label="Over50"
              />
            </Form.Group>
            <Form.Group controlId={`configApp-${code}-extraPublicAccess-alumni`}>
              <Form.Check
                defaultChecked={getSubentityFormValue(data, code, 'extraPublicAccess', 'alumni')}
                name={`configApp-${code}-extraPublicAccess-alumni`}
                type="checkbox"
                label="Alumni"
              />
            </Form.Group>
            <Form.Group controlId={`configApp-${code}-extraPublicAccess-community`}>
              <Form.Check
                defaultChecked={getSubentityFormValue(data, code, 'extraPublicAccess', 'community')}
                name={`configApp-${code}-extraPublicAccess-community`}
                type="checkbox"
                label="Community"
              />
            </Form.Group>
          </Col>
        )}
        {showSections['loginPublicAccess'] && (
          <Col sm={4}>
            <h2>Acceso público (login)</h2>
            <Form.Group controlId={`configApp-${code}-loginpublicAccess-aspirants`}>
              <Form.Check
                defaultChecked={getSubentityFormValue(data, code, 'loginPublicAccess', 'aspirants')}
                name={`configApp-${code}-loginPublicAccess-aspirants`}
                type="checkbox"
                label="Aspirants"
              />
            </Form.Group>
            <Form.Group controlId={`configApp-${code}-loginPublicAccess-over50`}>
              <Form.Check
                defaultChecked={getSubentityFormValue(data, code, 'loginPublicAccess', 'over50')}
                name={`configApp-${code}-loginPublicAccess-over50`}
                type="checkbox"
                label="Over50"
              />
            </Form.Group>
            <Form.Group controlId={`configApp-${code}-loginPublicAccess-alumni`}>
              <Form.Check
                defaultChecked={getSubentityFormValue(data, code, 'loginPublicAccess', 'alumni')}
                name={`configApp-${code}-loginPublicAccess-alumni`}
                type="checkbox"
                label="Alumni"
              />
            </Form.Group>
            <Form.Group controlId={`configApp-${code}-loginPublicAccess-community`}>
              <Form.Check
                defaultChecked={getSubentityFormValue(data, code, 'loginPublicAccess', 'community')}
                name={`configApp-${code}-loginPublicAccess-community`}
                type="checkbox"
                label="Community"
              />
            </Form.Group>
            <Form.Group controlId={`configApp-${code}-loginPublicAccess-guests`}>
              <Form.Check
                defaultChecked={getSubentityFormValue(data, code, 'loginPublicAccess', 'guests')}
                name={`configApp-${code}-loginPublicAccess-guests`}
                type="checkbox"
                label="Guests"
              />
            </Form.Group>
          </Col>
        )}
      </Row>
    </div>
  )
}
export default ConfigLogin
