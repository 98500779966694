import React from 'react'
import { Form, Row, Col } from 'react-bootstrap'

import { getFormValue } from '../../helpers/formdata'

const ConfigColors = ({ code, data, showSections }) => {
  return (
    <div className="bg-white">
      <h2 className="title">Colores</h2>
      <Row>
        {showSections['primary'] && (
          <Col sm={4}>
            <Form.Group controlId={`configApp-${code}-primary`}>
              <Form.Label>Primary</Form.Label>
              <Form.Control
                name={`configApp-${code}-primary`}
                defaultValue={getFormValue(data, code, 'primary')}
                type="text" />
            </Form.Group>
          </Col>
        )}
        {showSections['secondary'] && (
          <Col sm={4}>
            <Form.Group controlId={`configApp-${code}-secondary`}>
              <Form.Label>Secondary</Form.Label>
              <Form.Control
                name={`configApp-${code}-secondary`}
                defaultValue={getFormValue(data, code, 'secondary')}
                type="text" />
            </Form.Group>
          </Col>
        )}
        {showSections['tertiary'] && (
          <Col sm={4}>
            <Form.Group controlId={`configApp-${code}-tertiary`}>
              <Form.Label>Tertiary</Form.Label>
              <Form.Control
                name={`configApp-${code}-tertiary`}
                defaultValue={getFormValue(data, code, 'tertiary')}
                type="text" />
            </Form.Group>
          </Col>
        )}
      </Row>
      <Row>
        {showSections['secondaryLight'] && (
          <Col sm={{ span: 4, offset: 4 }}>
            <Form.Group controlId={`configApp-${code}-secondaryLight`}>
              <Form.Check
                defaultChecked={getFormValue(data, code, 'secondaryLight')}
                name={`configApp-${code}-secondaryLight`}
                type="checkbox"
                label="Márcalo si necesitas que el color del texto de los botones sea oscuro"
              />
            </Form.Group>
          </Col>
        )}
      </Row>
      <Row>
        {showSections['primaryDark'] && (
          <Col sm={4}>
            <Form.Group controlId={`configApp-${code}-primaryDark`}>
              <Form.Label>Primary Dark</Form.Label>
              <Form.Control
                name={`configApp-${code}-primaryDark`}
                defaultValue={getFormValue(data, code, 'primaryDark')}
                type="text" />
            </Form.Group>
          </Col>
        )}
        {showSections['secondaryDark'] && (
          <Col sm={4}>
            <Form.Group controlId={`configApp-${code}-secondaryDark`}>
              <Form.Label>Secondary Dark</Form.Label>
              <Form.Control
                name={`configApp-${code}-secondaryDark`}
                defaultValue={getFormValue(data, code, 'secondaryDark')}
                type="text" />
            </Form.Group>
          </Col>
        )}
        {showSections['tertiaryDark'] && (
          <Col sm={4}>
            <Form.Group controlId={`configApp-${code}-tertiaryDark`}>
              <Form.Label>Tertiary Dark</Form.Label>
              <Form.Control
                name={`configApp-${code}-tertiaryDark`}
                defaultValue={getFormValue(data, code, 'tertiaryDark')}
                type="text" />
            </Form.Group>
          </Col>
        )}
      </Row>
    </div>
  )
}
export default ConfigColors
