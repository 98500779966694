import React, { useState, useEffect} from 'react'
import { Form } from 'react-bootstrap'

const SelectSystem = ({ name, value, defaultValue }) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const handleChange = e => {
    setData(Array.from(
      e.target.selectedOptions,
      option => option.value
    ))
  }
  useEffect(() => {
    setLoading(false)
    setData(value)
  }, [value])

  if (loading) {
    return false
  }
  return (
    <Form.Control
      onChange={handleChange}
      value={data || defaultValue}
      name={name}
      as="select"
    >
      <option value="default">-- Sin sistema fuente --</option>
      <option value="academic">Académico</option>
      <option value="economic">Económico</option>
      <option value="investigation">Investigación</option>
      <option value="humanresource">Recursos Humanos</option>
      <option value="securetitle_idgriu">Título Seguro</option>
      <option value="spaces">Espacios UXXI</option>
      <option value="tui_basicauth">TUI BasicAuth</option>
      <option value="tui_soap">TUI SOAP</option>
      <option value="blackboard">Blackboard</option>
      <option value="moodle">Moodle</option>
      <option value="cms">CMS</option>
      <option value="ufv">UFV</option>
      <option value="portal">Portal</option>
      <option value="stack">Stack</option>
      <option value="comunica">Comunica</option>
      <option value="studium">Studium</option>
    </Form.Control>
  )
}
export default SelectSystem
