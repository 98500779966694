import React from 'react'
import { Form, Row, Col } from 'react-bootstrap'

import { getFormValue } from '../../helpers/formdata'

const configTwitter = ({ code, data, showSections }) => {
  return (
    <div className="bg-white">
      <h2 className="title">Twitter</h2>
      <Row>
        {showSections['account'] && (
          <Col sm={8}>
            <Form.Group controlId={`configApp-${code}-account`}>
              <Form.Label>Cuenta de Twitter</Form.Label>
              <Form.Control
                name={`configApp-${code}-account`}
                defaultValue={getFormValue(data, code, 'account')}
                type="text" />
            </Form.Group>
          </Col>
        )}
      </Row>
    </div>
  )
}
export default configTwitter
