import React, { useState, useEffect } from 'react'
import { Form, Button, Card, Accordion } from 'react-bootstrap'
import { orderByName } from '../../helpers/utils'
import { checkStatus } from '../../helpers/utils'
import GroupItem from './groupItem'

const GroupCard = ({ identity, group, code, data, items }) => {
  const [enabled, setEnabled] = useState(false)
  const [checkId, setCheckId] = useState('')
  const onClick = () => {
    setEnabled(!enabled)
  }
  useEffect(() => {
    if (data) {
      setEnabled(checkStatus(`${identity}Id`, data, items))
      setCheckId(`${identity}-${group}`)
    }
  }, [identity, data, items, group])
  return (
    <Card className="card-check">
      <Accordion.Item eventKey={group}>
      <Card.Header className={enabled ? 'checked' : ''}>
        <Accordion.Button
          as={Button}
          variant="block text-start"
        >
          {group}
        </Accordion.Button>
      </Card.Header>
      <Accordion.Body>
        <Card.Body>
          <Form.Group className="check-group">
            <Form.Check
              checked={enabled}
              id={checkId}
              name={code}
              type="checkbox"
              label="Activo"
              onChange={() => false}
              onClick={onClick}
            />
          </Form.Group>
          {items.sort(orderByName).map((item, indx) => (
            <GroupItem
              key={indx}
              item={item}
              data={data}
              identity={identity}
              enabled={enabled}
            />
          ))}
        </Card.Body>
      </Accordion.Body>
    </Accordion.Item>
    </Card>
  )
}
export default GroupCard
