import React, { useState, useEffect } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'

const ModalUpdate = ({ id, show, onConfirm, onClose }) => {
  const [baseUrl, setBaseUrl] = useState('')
  const [validate, setValidate] = useState(true)
  const urlRegex = /^http(s)?:\/\/[^#?/]+/
  const onUpdate = () => {
    if (urlRegex.test(baseUrl)) {
      onConfirm(baseUrl)
    }
    setValidate(false)
  }
  const onChange = e => {
    setBaseUrl(e.target.value)
  }
  useEffect(() => {
    if (show === false) {
      setBaseUrl('')
      setValidate(true)
    }
  }, [show])
  return (
    <Modal show={show} size="md" onHide={onClose}>
      <Modal.Header>Actualizar baseUrl</Modal.Header>
      <Modal.Body>
        <Form.Group controlId="baseUrl">
          <h3 className="title">
            Introduce un baseUrl y se reemplazarán todos los endpoints
          </h3>
          <Form.Label>BaseUrl</Form.Label>
          <Form.Control
            name="baseUrl"
            type="text"
            defaultValue={baseUrl}
            onChange={onChange}
          />
          {validate === false && (
            <Form.Text className="text-muted">
              La url introducida no es correcta o no está bien formada
            </Form.Text>
          )}
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Cancelar
        </Button>
        <Button variant="delete" onClick={onUpdate}>
          Actualizar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
export default ModalUpdate