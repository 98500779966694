import { Auth } from 'aws-amplify'
import { getCache } from './cache'
import { flushCache } from './cache'

export const hasRole = role => {
  const user = getCache('user')
  const roles = (user && user.roles)
    ? user.roles.filter(a => a.code === role)
    : []
  return Boolean(roles.length)
}
export const getToken = () => {
  const user = getCache('user')
  const token = (user && user.api_token)
    ? user.api_token
    : ''
  return token
}
export const getSetup = url => {
  const bearer = 'Bearer ' + getToken()
  const headers = { 'Authorization': bearer }
  return {
    'headers': headers,
    'url': url
  }
}
export const responseError = res => {
  switch (res.status) {
    case 401:
      throw Error('Unauthorized')
    default:
      throw Error('Error')
  }
}
export const responseLogout = (res, dispatch) => {
  if (res.status === 401) {
    dispatch(logout())
  }
}
export const logout = type => {
  Auth.signOut()
  flushCache()
  return {
    type: type || 'UNAUTHENTICATED'
  }
}
