import * as types from '../actions/actionTypes'

const initialState = {
  items: [],
  loading: false,
  error: null
}
const credential = (state = initialState, action) => {
  switch(action.type) {
    case types.FETCH_CREDENTIAL_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      }
    case types.FETCH_CREDENTIAL_LIST:
      return {
        ...state,
        items: action.payload.items,
        loading: false,
        error: null
      }
    case types.FETCH_CREDENTIAL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      }
    default:
      return state
  }
}
export default credential
