import React, { useState, useEffect } from 'react'
import { Form, Row, Col } from 'react-bootstrap'

const SwitchForm = ({ name, value, defaultValue, options, children }) => {
  const [data, setData] = useState('')
  const [loading, setLoading] = useState(true)
  const handleChange = e => {
    setData(Array.from(
      e.target.selectedOptions,
      option => option.value
    ))
  }
  useEffect(() => {
    setLoading(false)
    setData(value || defaultValue)
  }, [value, defaultValue])
  if (loading) {
    return false
  }
  return (<>
    <Row>
      <Col sm={4}>
        <Form.Control
          onChange={handleChange}
          value={data}
          name={name}
          as="select"
        >
          {options.map((item, indx) => (
            <option key={indx} value={item.value}>
              {item.name}
            </option>
          ))}
        </Form.Control>
      </Col>
    </Row>
    {children.map(item => item.props.id === data.toString() && item)}
  </>)
}
export default SwitchForm