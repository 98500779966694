import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Accordion, Row, Col, Button } from 'react-bootstrap'
import { fetchCredentials } from '../../actions/credential'
import { createCredentials } from '../../actions/credential'
import { deleteCredentials } from '../../actions/credential'
import { isAdminRole } from '../../helpers/roles'
import { orderByName } from '../../helpers/utils'
import { ModalDelete } from '../../components'
import CredentialCreate from './credentialCreate'
import CredentialItem from './credentialItem'

const CredentialList = ({ identity, id }) => {
  const [create, setCreate] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const [credential, setCredential] = useState(null)
  const items = useSelector((state) => state.credential.items)
  const dispatch = useDispatch()
  const onCreate = (e) => {
    setCreate(true)
  }
  const onSubmit = (name) => {
    setCreate(false)
    dispatch(
      createCredentials({
        universityId: id,
        name: name,
      })
    )
  }
  const onConfirm = () => {
    setConfirm(false)
    dispatch(
      deleteCredentials({
        universityId: id,
        credentialId: credential,
      })
    )
  }
  const onDelete = (id) => {
    setCredential(id)
    setConfirm(true)
  }
  const onClose = () => {
    setCreate(false)
    setConfirm(false)
  }
  useEffect(() => {
    dispatch(fetchCredentials(id))
  }, [id, dispatch])
  return (
    <>
      {isAdminRole() && (
      <Row>
        <Col>
          <Button
            className="btn-create float-end"
            onClick={onCreate}
            type="button"
          >
            Añadir credencial
          </Button>
        </Col>
      </Row>)}
      <Accordion defaultActiveKey="0">
        {items.sort(orderByName).map((item, indx) => (
          <CredentialItem
            key={indx}
            identity={identity}
            data={item}
            onDelete={onDelete}
          />
        ))}
      </Accordion>
      <CredentialCreate show={create} onClose={onClose} onSubmit={onSubmit} />
      <ModalDelete show={confirm} onClose={onClose} onConfirm={onConfirm} />
    </>
  )
}
export default CredentialList
