import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { logout } from '../helpers/auth';

const Logout = () => {
  const dispatch = useDispatch();
  const stage = process.env.REACT_APP_STAGE;
  const display = `{${stage}}`;
  const redirectLogoutUrl = process.env.REACT_APP_COGNITO_REDIRECT_SIGN_OUT;

  useEffect(() => {
    dispatch(logout());
  }, [dispatch]);

  setTimeout(() => {
    window.location.href = redirectLogoutUrl;
  }, 1500);

  return (
    <Container className="my-auto">
      <Row className="justify-content-md-center">
        <Col sm="8" className="login align-self-center">
          <div className="text-center">
            <img src="/login.png" alt="Login UXXI" />
            <div className="login-stage">{display}</div>
          </div>
          <div className="actions text-center">
            <p className="title-login">Se ha cerrado correctamente la sesión</p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
export default Logout;
