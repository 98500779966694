import React from 'react'
import { Form, Row, Col } from 'react-bootstrap'

import SelectCurrency from '../widgets/selectCurrency'
import SelectCurrencyLang from '../widgets/selectCurrencyLang'
import { getFormValue } from '../../helpers/formdata'

const ConfigCurrency = ({ code, data, showSections }) => {
  return (
    <div className="bg-white">
      <h2 className="title">Moneda</h2>
      <Row>
        {showSections['currency'] && (
          <Col sm={4}>
            <Form.Group controlId={`configApp-${code}-currency`}>
              <Form.Label>Moneda (ISO)</Form.Label>
              <SelectCurrency
                name={`configApp-${code}-currency`}
                value={getFormValue(data, code, 'currency')}
              />
            </Form.Group>
          </Col>
        )}
      </Row>
      <Row>
        {showSections['currencyLang'] && (
          <Col sm={4}>
            <Form.Group controlId={`configApp-${code}-currencyLang`}>
              <Form.Label>Lenguaje de moneda</Form.Label>
              <SelectCurrencyLang
                name={`configApp-${code}-currencyLang`}
                value={getFormValue(data, code, 'currencyLang')}
              />
            </Form.Group>
          </Col>
        )}
      </Row>
    </div>
  )
}
export default ConfigCurrency
