import React, { useState, useEffect} from 'react'
import { Form } from 'react-bootstrap'

const SelectUCRoles = ({ code, name, value, items }) => {
  const [data, setData] = useState([])
  const handleChange = e => {
    setData(Array.from(
      e.target.selectedOptions,
      option => option.value
    ))
  }
  useEffect(() => {
    if (value && code in value) {
      setData(value[code])
    }
  }, [value, code])
  return (<>
    <Form.Label>{name}</Form.Label>
    <Form.Control
      onChange={handleChange}
      name={`roleuc-${code}`}
      value={data}
      as="select"
      multiple
    >
      <option value="LOGIN_INSTI">
        LOGIN INSTITUCIONAL
      </option>
      {items.map((item, indx) => (
        <option key={indx} value={item.rolemapId}>
          {item.name.toUpperCase()}
        </option>
      ))}
    </Form.Control>
  </>)
}
export default SelectUCRoles