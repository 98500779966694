import React from 'react'
import { Form, Row, Col } from 'react-bootstrap'

import { getFormValue } from '../../helpers/formdata'
import { getSubentityFormValue } from '../../helpers/formdata'

const ConfigTraining = ({ code, data, showSections }) => {
  return (
    <div className="bg-white">
      <h2 className="title">Formación</h2>
      <Row>
        {showSections['useTrainingDownload'] && (
          <Col sm={4}>
            <Form.Group controlId={`configApp-${code}-useTrainingDownload`}>
              <Form.Check
                defaultChecked={getFormValue(data, code, 'useTrainingDownload')}
                name={`configApp-${code}-useTrainingDownload`}
                type="checkbox"
                label="Mostrar descarga de formación"
              />
            </Form.Group>
          </Col>
        )}
      </Row>
      <Row>
        {showSections['trainingTabs'] && (
          <Col sm={4}>
            <h2>Pestañas de formación</h2>
            <Form.Group controlId={`configApp-${code}-trainingTabs-completed`}>
              <Form.Check
                defaultChecked={getSubentityFormValue(data, code, 'trainingTabs', 'completed')}
                name={`configApp-${code}-trainingTabs-completed`}
                type="checkbox"
                label="Completed"
              />
            </Form.Group>
            <Form.Group controlId={`configApp-${code}-trainingTabs-requested`}>
              <Form.Check
                defaultChecked={getSubentityFormValue(data, code, 'trainingTabs', 'requested')}
                name={`configApp-${code}-trainingTabs-requested`}
                type="checkbox"
                label="Requested"
              />
            </Form.Group>
            <Form.Group controlId={`configApp-${code}-trainingTabs-available`}>
              <Form.Check
                defaultChecked={getSubentityFormValue(data, code, 'trainingTabs', 'available')}
                name={`configApp-${code}-trainingTabs-available`}
                type="checkbox"
                label="Available"
              />
            </Form.Group>
          </Col>
        )}
      </Row>
    </div>
  )
}
export default ConfigTraining
