import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';

import { getFormValue } from '../../helpers/formdata';
import { languages } from '../../data/languages';

const ConfigLanguages = ({ code, data, showSections }) => {
  return (
    <div className="bg-white">
      <h2 className="title">Idiomas</h2>
      {showSections['languages'] && (
        <>
          <Row className="margin-bottom">
            <Col sm={6}>
              <small>Idioma</small>
            </Col>
            <Col sm={1} className="text-center">
              <small>Activo</small>
            </Col>
            <Col sm={1}>
              <small>Orden</small>
            </Col>
          </Row>
          {languages.map((item, indx) => (
            <Row key={indx}>
              <Col sm={6}>
                <p>{item.name}</p>
              </Col>
              <Col sm={1}>
                <Form.Group controlId={`configApp-${code}-${item.code}`}>
                  <Form.Check
                    defaultChecked={getFormValue(data, code, item.code)}
                    name={`configApp-${code}-${item.code}`}
                    className="text-center"
                    type="checkbox"
                  />
                </Form.Group>
              </Col>
              <Col sm={1}>
                <Form.Group controlId={`configApp-${code}Order-${item.code}`}>
                  <Form.Control
                    name={`configApp-${code}Order-${item.code}`}
                    defaultValue={getFormValue(
                      data,
                      `${code}Order`,
                      `${item.code}`
                    )}
                    className="form-order"
                    type="text"
                  />
                </Form.Group>
              </Col>
            </Row>
          ))}
        </>
      )}
    </div>
  );
};
export default ConfigLanguages;
