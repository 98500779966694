import React from 'react'
import { Form, Row, Col } from 'react-bootstrap'

import { getFormValue } from '../../helpers/formdata'

const ConfigTutors = ({ code, data, showSections }) => {
  return (
    <div className="bg-white">
      <h2 className="title">Tutores</h2>
      {showSections['showTutorPhone'] && (
        <Row>
          <Col sm={4}>
            <Form.Group controlId={`configApp-${code}-showTutorPhone`}>
              <Form.Check
                defaultChecked={getFormValue(data, code, 'showTutorPhone')}
                name={`configApp-${code}-showTutorPhone`}
                type="checkbox"
                label="Mostrar teléfono de los tutores"
              />
            </Form.Group>
          </Col>
        </Row>
      )}
    </div>
  )
}
export default ConfigTutors
