import React from 'react'
import { Form, Row, Col } from 'react-bootstrap'

import { getFormValue } from '../../helpers/formdata'

const ConfigCDU = ({ code, data, showSections }) => {
  return (
    <div className="bg-white">
      <h2 className="title">CDU</h2>
      {showSections['cduBackend'] && (
        <Row>
          <Col sm={8}>
            <Form.Group controlId={`configApp-${code}-cduBackend`}>
              <Form.Label>Url Backend</Form.Label>
              <Form.Control
                name={`configApp-${code}-cduBackend`}
                defaultValue={getFormValue(data, code, 'cduBackend')}
                type="text" />
            </Form.Group>
          </Col>
        </Row>
      )}
      {showSections['cduInfo'] && (
        <Row>
          <Col sm={8}>
            <Form.Group controlId={`configApp-${code}-cduInfo`}>
              <Form.Label>Url servicio de información</Form.Label>
              <Form.Control
                name={`configApp-${code}-cduInfo`}
                defaultValue={getFormValue(data, code, 'cduInfo')}
                type="text" />
            </Form.Group>
          </Col>
        </Row>
      )}
    </div>
  )
}
export default ConfigCDU
