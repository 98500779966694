import React from 'react'
import { Form, Button, Row, Col, Card, Accordion } from 'react-bootstrap'
import { getFormValue } from '../../helpers/formdata'
import SelectAuth from '../widgets/selectAuth'

const AuthenticationMain = ({ authType, authStack, authTracking, data }) => (
  <Card>
    <Accordion.Item>
      <Card.Header>
        <Accordion.Button as={Button} variant="block text-start">
          Datos autenticación
        </Accordion.Button>
      </Card.Header>
      <Accordion.Body>
        <Card.Body>
          <Row>
            <Col>
              <Form.Group controlId={`authentication-main-identityPoolId`}>
                <Form.Label>Identity pool Id</Form.Label>
                <Form.Control
                  name={`authentication-main-identityPoolId`}
                  defaultValue={getFormValue(data, 'main', 'identityPoolId')}
                  type="text"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId={`authentication-main-userPoolId`}>
                <Form.Label>User pool Id</Form.Label>
                <Form.Control
                  name={`authentication-main-userPoolId`}
                  defaultValue={getFormValue(data, 'main', 'userPoolId')}
                  type="text"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId={`authentication-main-appClientId`}>
                <Form.Label>App client Id</Form.Label>
                <Form.Control
                  name={`authentication-main-appClientId`}
                  defaultValue={getFormValue(data, 'main', 'appClientId')}
                  type="text"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId={`authentication-main-redirectSignOut`}>
                <Form.Label>Redirección cierre de sesión</Form.Label>
                <Form.Control
                  name={`authentication-main-redirectSignOut`}
                  defaultValue={getFormValue(data, 'main', 'redirectSignOut')}
                  type="text"
                />
              </Form.Group>
            </Col>
          </Row>
          <Form.Group controlId="authType">
            <Form.Label>Tipo Autenticación</Form.Label>
            <SelectAuth name="authType" value={authType} />
          </Form.Group>
          <hr />
          <Form.Group controlId={`authStack`}>
            <Form.Check
              name="authStack"
              label="Deshabilitar los servicios de stack"
              defaultChecked={authStack}
              type="checkbox"
            />
          </Form.Group>
          <Form.Group controlId={`authTracking`}>
            <Form.Check
              name="authTracking"
              label="Mostrar tiempos de acceso en logs"
              defaultChecked={authTracking}
              type="checkbox"
            />
          </Form.Group>
        </Card.Body>
      </Accordion.Body>
    </Accordion.Item >
  </Card>
)
export default AuthenticationMain