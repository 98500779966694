export const sectionUpdateAllowed = [
  'academic',
  'economic',
  'investigation',
  'humanresource'
]
export const endpointUpdateNotAllowed = [
  'utilities_download_file',
  'utilities_university_card',
  'utilities_usal_schedules',
  'utilities_recent_feed',
  'utilities_student_courses',
  'utilities_recent_tweets'
]