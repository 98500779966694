import React, { useState, useEffect } from 'react'
import { Form } from 'react-bootstrap'
import { normalize } from '../../helpers/utils'

const NotificationItem = ({ item, data }) => {
  const [code, setCode] = useState(null)
  useEffect(() => {
    setCode(normalize(item.notificationId))
  }, [item])
  if (!code) { return false }
  return (<>
    <h4>{item.notificationId}</h4>
    <h5>{item.name}</h5>
    <Form.Group controlId={`notification-${code}-enabled`} className="check-group inline">
      <Form.Check
        name={`notification-${code}-enabled`}
        defaultChecked={data && data[code] && data[code]['enabled']}
        type="checkbox"
        label="Activo"
      />
    </Form.Group>
    <Form.Group controlId={`notification-${code}-persistent`} className="check-group inline">
      <Form.Check
        name={`notification-${code}-persistent`}
        defaultChecked={data && data[code] && data[code]['persistent']}
        type="checkbox"
        label="Persistente"
      />
    </Form.Group>
  </>)
}
export default NotificationItem