import React, { useState, useEffect } from 'react'
import { Outlet, Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

const LoginRequired = () => {
  const isLogin = useSelector(state => state.auth.isLogin)
  const [loaded, setLoaded] = useState(false)
  useEffect(() => {
    setLoaded(true)
  }, [])
  return isLogin
    ? <Outlet context={[loaded]} />
    : <Navigate to="/login" />
}
export default LoginRequired
