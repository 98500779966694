import * as types from '../actions/actionTypes'

const initialState = {
  items: [],
  item: null,
  loading: false,
  error: null
}
const university = (state = initialState, action) => {
  switch(action.type) {
    case types.FETCH_UNIVERSITY_BEGIN:
      return {
        ...state,
        item: null,
        loading: true,
        error: null
      }
    case types.FETCH_UNIVERSITY_LIST:
      return {
        ...state,
        items: action.payload.items,
        loading: false,
        error: null
      }
    case types.FETCH_UNIVERSITY_ITEM:
      return {
        ...state,
        item: action.payload.item,
        loading: false,
        error: null
      }
    case types.FETCH_UNIVERSITY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      }
    case types.FETCH_VERSIONS:
      return {
        ...state,
        versions: action.payload.versions,
        loading: false,
        error: null
      }
    default:
      return state
  }
}
export default university
