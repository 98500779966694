import React from 'react'
import { Form, Row, Col } from 'react-bootstrap'

import { getFormValue } from '../../helpers/formdata'

const ConfigRateAndShare = ({ code, data, showSections }) => {
  return (
    <div className="bg-white">
      <h2 className="title">Valorar y compartir</h2>
      {showSections['shareAndRate'] && (
        <Row>
          <Col sm={4}>
            <Form.Group controlId={`configApp-${code}-shareAndRate`}>
              <Form.Check
                defaultChecked={getFormValue(data, code, 'shareAndRate')}
                name={`configApp-${code}-shareAndRate`}
                type="checkbox"
                label="Activar valorar y compartir"
              />
            </Form.Group>
          </Col>
        </Row>
      )}
      {showSections['rateAndShareGuest'] && (
        <Row>
          <Col sm={4}>
            <Form.Group controlId={`configApp-${code}-rateAndShareGuest`}>
              <Form.Check
                defaultChecked={getFormValue(data, code, 'rateAndShareGuest')}
                name={`configApp-${code}-rateAndShareGuest`}
                type="checkbox"
                label="Activar valorar y compartir para invitados"
              />
            </Form.Group>
          </Col>
        </Row>
      )}
    </div>
  )
}
export default ConfigRateAndShare
