import React, { useState, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchUniversities } from '../actions/university';
import { fetchUniversity } from '../actions/university';
import { createUniversity } from '../actions/university';
import { updateUniversity } from '../actions/university';
import { deleteUniversity } from '../actions/university';
import { closeSessionUniversity } from '../actions/university';
import { duplicateUniversity } from '../actions/university';
import { launchCatalogUpload } from '../actions/catalog';
import { fetchVersions } from '../actions/university';
import { getFormData } from '../helpers/formdata';
import { getExportData } from '../helpers/formdata';
import { getListData } from '../helpers/formdata';
import { UniversityList } from '../components';
import { UniversityItem } from '../components';
import { ModalUpdate } from '../components';
import { ModalConfirm } from '../components';
import { ModalDelete } from '../components';
import { ModalDuplicate } from '../components';
import { ModalSession } from '../components';
import { ModalUploadCatalog } from '../components';
import { LayoutBase } from '../layouts';

const Universities = () => {
  const navigate = useNavigate();
  const [isCreate, setCreate] = useState(false);
  const [showModalUpdate, setModalUpdate] = useState(false);
  const [showModalConfirm, setModalConfirm] = useState(false);
  const [showModalDelete, setModalDelete] = useState(false);
  const [showModalDuplicate, setModalDuplicate] = useState(false);
  const [showModalSession, setModalSession] = useState(false);
  const [showModalUploadCatalog, setModalUploadCatalog] = useState(false);
  const [selected, setSelected] = useState(null);
  const [baseUrl, setBaseUrl] = useState(null);
  const [target, setTarget] = useState(null);
  const [loaded] = useOutletContext();
  const items = useSelector((state) => state.university.items);
  const item = useSelector((state) => state.university.item);
  const versions = useSelector((state) => state.university.versions);
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    if (!loaded) return;
    if (id === undefined) {
      dispatch(fetchUniversities());
    } else {
      dispatch(fetchUniversity(id));
      dispatch(fetchVersions(id));
    }
  }, [id, loaded, dispatch]);

  const onSubmit = (e) => {
    e.preventDefault();
    const action = document.activeElement.value;
    if (action === 'save') return onSave(e.target, null);
    if (action === 'export') return onExport(e.target);
    if (action === 'update') return onUpdate(e.target);
    if (action === 'catalogUpload') return catalogUpload();
    if (action === 'scheduledCatalogUpload') return scheduledCatalogUpload();
    throw new Error('Method not allowed');
  };
  const catalogUpload = () => {
    setModalUploadCatalog(true);
  };

  const onLoadCatalog = (listLanguagesLoadCatalog) => {
    let selectedLanguages = Object.keys(listLanguagesLoadCatalog)
      .filter((key) => listLanguagesLoadCatalog[key])
      .map((key) => (key === 'va' ? 'ca' : key));

    selectedLanguages = [...new Set(selectedLanguages)].join(',');

    dispatch(launchCatalogUpload(id, item.secretKey, selectedLanguages));
    setModalUploadCatalog(false);
  };

  const scheduledCatalogUpload = () => {
    console.log('scheduledCatalogUpload');
  };

  const onModalUpdate = (url) => {
    setModalUpdate(false);
    setModalConfirm(true);
    setBaseUrl(url);
  };
  const onConfirmUpdate = () => {
    setModalUpdate(false);
    setModalConfirm(false);
    onSave(target, baseUrl);
  };
  const onConfirmDelete = () => {
    dispatch(deleteUniversity(selected));
    setModalDelete(false);
    onCancel();
  };
  const onConfirmDuplicate = (data) => {
    dispatch(duplicateUniversity(selected, data));
    setModalDuplicate(false);
    onCancel();
  };
  const onConfirmSession = () => {
    dispatch(closeSessionUniversity(selected));
    setModalSession(false);
    onCancel();
  };
  const onSave = (target, url) => {
    const data = getFormData(target, url, {});
    const config = data.configApp || {};
    const services = getExportData(target, []);
    const download = getListData(config, 'download');
    const screens = getListData(config, 'screens');
    const enabled = services.concat(download, screens);

    data.configApp = config;
    data.configApp.enabled = enabled;
    isCreate
      ? dispatch(createUniversity(data))
      : dispatch(updateUniversity(data));
    onCancel();
  };
  const onUpdate = (target) => {
    setTarget(target);
    setModalUpdate(true);
  };
  const onExport = (target) => {
    const data = getExportData(target, []);
    const element = document.createElement('a');
    const body = document.body;
    const items = data.join('","');
    const services = items ? `"${items}"` : '';
    const result = `AVAILABLE_SERVICES=[${services}]`;
    const value = encodeURIComponent(result);
    element.setAttribute('href', `data:text/plaincharset=utf-8,${value}`);
    element.setAttribute('download', 'services_enabled.txt');
    body.appendChild(element);
    element.click();
    body.removeChild(element);
  };
  const onDelete = (id) => {
    setSelected(id);
    setModalDelete(true);
  };
  const onDuplicate = (id) => {
    setSelected(id);
    setModalDuplicate(true);
  };
  const onSession = (id) => {
    setSelected(id);
    setModalSession(true);
  };
  const onCancel = () => {
    navigate('/universities');
    setCreate(false);
  };
  const onClose = () => {
    setModalUpdate(false);
    setModalConfirm(false);
    setModalDelete(false);
    setModalDuplicate(false);
    setModalSession(false);
    setModalUploadCatalog(false);
  };

  return (
    <LayoutBase>
      <ModalUpdate
        show={showModalUpdate}
        onClose={onClose}
        onConfirm={onModalUpdate}
      />
      <ModalConfirm
        show={showModalConfirm}
        onClose={onClose}
        onConfirm={onConfirmUpdate}
      />
      <ModalDelete
        show={showModalDelete}
        onClose={onClose}
        onConfirm={onConfirmDelete}
      />
      <ModalDuplicate
        show={showModalDuplicate}
        onClose={onClose}
        onConfirm={onConfirmDuplicate}
      />
      <ModalSession
        show={showModalSession}
        onClose={onClose}
        onConfirm={onConfirmSession}
      />
      <ModalUploadCatalog
        show={showModalUploadCatalog}
        onClose={onClose}
        onLoadCatalog={onLoadCatalog}
      />
      {id || isCreate ? (
        <UniversityItem
          {...item}
          isUpdate={!isCreate}
          onSubmit={onSubmit}
          onDuplicate={onDuplicate}
          onExport={onExport}
          onDelete={onDelete}
          onSession={onSession}
          versions={versions}
        />
      ) : (
        <UniversityList items={items} onCreate={setCreate} />
      )}
    </LayoutBase>
  );
};
export default Universities;
