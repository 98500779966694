import * as types from '../actions/actionTypes'

const auth = (state = {}, action) => {
  switch(action.type) {
    case types.AUTHENTICATED:
      return {
        ...state,
        isLogin: true,
        user: action.payload.user,
        error: null
      }
    case types.UNAUTHENTICATED:
      return {
        ...state,
        isLogin: false,
        error: null
      }
    case types.AUTHENTICATION_ERROR:
      return {
        ...state,
        error: action.payload.error
      }
    default:
      return state
  }
}
export default auth
