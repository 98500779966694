import React, { useState, useEffect} from 'react'
import { Form } from 'react-bootstrap'

const SelectCurrency = ({ name, value }) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const handleChange = e => {
    setData(Array.from(
      e.target.selectedOptions,
      option => option.value
    ))
  }
  useEffect(() => {
    setLoading(false)
    setData(value)
  }, [value])
  if (loading) {
    return false
  }
  return (
    <Form.Control
      onChange={handleChange}
      value={data}
      name={name}
      as="select"
    >
      <option value="eur">EUR (Euro)</option>
      <option value="cop">COP (Peso colombiano)</option>
      <option value="usd">USD (Dólar estadounidense)</option>
      <option value="pen">PEN (Nuevo Sol)</option>
      <option value="dop">DOP (Peso dominicano)</option>
      <option value="clp">CLP (Peso chileno)</option>
      <option value="uyu">UYU (Peso uruguayo)</option>
      <option value="nio">NIO (Córdoba oro)</option>
      <option value="mxn">MXN (Peso mexicano)</option>
      <option value="vef">VEF (Bolívar)</option>
    </Form.Control>
  )
}
export default SelectCurrency