import React from 'react'
import { Form } from 'react-bootstrap'
import { getFormValue } from '../../helpers/formdata'

const RolemapBase = ({ code, rolemap, label, children }) => (<>
  <Form.Group controlId={`rolemap-${code}-name`}>
    <Form.Label>{label}</Form.Label>
    <Form.Control
      name={`rolemap-${code}-name`}
      defaultValue={getFormValue(rolemap, code, 'name')}
      type="text"
      as="textarea"
    />
  </Form.Group>
  {children}
</>)
export default RolemapBase
