import React from 'react'
import { Form, Row, Col } from 'react-bootstrap'

import SelectDownload from '../widgets/selectDownload'
import { getFormValue } from '../../helpers/formdata'

const ConfigEconomic = ({ code, data, showSections }) => {
  return (
    <div className="bg-white">
      <h2 className="title">Situación económica</h2>
      <Row>
        {showSections['useReport'] && (
          <Col sm={12}>
            <Form.Group controlId={`configApp-${code}-useReport`}>
              <Form.Check
                defaultChecked={getFormValue(data, code, 'useReport')}
                name={`configApp-${code}-useReport`}
                type="checkbox"
                label="Mostrar reporte de situación económica"
              />
            </Form.Group>
          </Col>
        )}
        {showSections['useReportProject'] && (
          <Col sm={12}>
            <Form.Group controlId={`configApp-${code}-useReportProject`} className="inline">
              <Form.Check
                defaultChecked={getFormValue(data, code, 'useReportProject')}
                name={`configApp-${code}-useReportProject`}
                type="checkbox"
                label="Mostrar reporte de situación económica por proyecto"
              />
            </Form.Group>
            <Form.Group controlId={`configApp-downloadTypes-useReportProject`} className="inline">
              <SelectDownload
                name={`configApp-downloadTypes-useReportProject`}
                value={getFormValue(data, `downloadTypes`, 'useReportProject')}
              />
            </Form.Group>
          </Col>
        )}
      </Row>
    </div>
  )
}
export default ConfigEconomic
