import React, { useEffect } from 'react';
import { Navbar, Nav, Container, Row, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { logout } from '../helpers/auth';
import { Auth } from 'aws-amplify';

const getNav = (appCode) => {
  const sections = [
    {
      to: '/universities',
      icon: 'icon-shield',
      title: 'Gestión universidades',
    },
  ];

  if (appCode !== 'PS') {
    sections.push(
      {
        to: '/',
        icon: 'icon-chart',
        title: 'Estadísticas de uso',
      },
      {
        to: '/',
        icon: 'icon-activity',
        title: 'Salud de la plataforma',
      }
    );
  }

  let nav = (
    <Nav className="navbar-sidebar">
      {sections.map((section, index) => (
        <Nav.Link
          as={Link}
          to={section.to}
          key={index}
          className={index === 0 ? 'active' : ''}
        >
          <span className={`icon ${section.icon}`}></span>
          {section.title}
        </Nav.Link>
      ))}
    </Nav>
  );

  return nav;
};

const LayoutBase = ({ children }) => {
  const name = `${process.env.REACT_APP_NAME}`;
  const code = `${process.env.REACT_APP_CODE}`;
  const stage = `${process.env.REACT_APP_STAGE}`;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = () => {
    dispatch(logout('LOGOUT'));
  };
  const appName = {
    PS: 'Panel de administración',
    APP: code,
    ALUMNI: code,
  };

  const stageDict = {
    test: 'test',
    develop: 'develop',
    staging: 'stage',
    prod: 'producción',
  };

  const checkTokenExpiration = async () => {
    try {
      const session = await Auth.currentSession();
      const expirationTimestamp = session.getIdToken().getExpiration();
      const expirationDate = new Date(expirationTimestamp * 1000);
      const isExpired = expirationDate < new Date();

      if (isExpired) {
        console.log('Token expirado. Cerrando sesión...');
        navigate('/logout');
      } else {
        console.log('Token válido', expirationDate);
      }
    } catch (error) {
      console.error('Error obteniendo la sesión', error);
      navigate('/logout');
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      checkTokenExpiration();
    }, 180000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <>
      <Navbar expand="md" className="fixed-top">
        <Navbar.Brand as={Link} to="/">
          <img src="/logo.svg" alt="UXXI" />
          <div className="navbar-stage">
            {appName[code]} ({stageDict[stage]})
          </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto" />
          <Nav className="navbar-actions">
            <Nav.Link as={Link} onClick={handleLogout}>
              <span className="icon-power"></span>
              Cerrar sesión
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <Container fluid>
        <Row>
          <Col md={2} className="d-none d-md-block sidebar h-100">
            {getNav(code)}
          </Col>
          <Col md={10} className="ms-sm-auto ps-0 pe-0">
            <div className="content">{children}</div>
            <footer className="footer">
              <Row>
                <Col md={6}>
                  <div className="footer-info">
                    <p className="footer-title">
                      {name}
                      <span>
                        Accesibilidad &nbsp;|&nbsp; Aviso legal y política de
                        privacidad
                      </span>
                    </p>
                  </div>
                </Col>
                <Col md={2}>
                  <div className="footer-logo">
                    <img src="/logo.svg" alt="UXXI" />
                  </div>
                </Col>
                <Col md={4}>
                  <div className="text-end footer-legal">Desarrollado por</div>
                  <div className="text-end footer-legal">
                    <img src="/logo.svg" alt="UXXI" height="8" /> UNIVERSITAS
                    XXI
                  </div>
                  <div className="text-end footer-legal">
                    &copy; 2022. Todos los derechos reservados
                  </div>
                </Col>
              </Row>
            </footer>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default LayoutBase;
