import { combineReducers } from 'redux'
import auth from './auth'
import university from './university'
import credential from './credential'

const rootReducer = combineReducers({
  auth,
  university,
  credential
})
export default rootReducer
