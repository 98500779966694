import React, { useState, useEffect} from 'react'
import { Form } from 'react-bootstrap'

const SelectCurrencyLang = ({ name, value }) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const handleChange = e => {
    setData(Array.from(
      e.target.selectedOptions,
      option => option.value
    ))
  }
  useEffect(() => {
    setLoading(false)
    setData(value)
  }, [value])
  if (loading) {
    return false
  }
  return (
    <Form.Control
      onChange={handleChange}
      value={data}
      name={name}
      as="select"
    >
      <option value="es">es</option>
      <option value="es-CO">es-CO</option>
      <option value="es-EC">es-EC</option>
      <option value="es-PE">es-PE</option>
      <option value="es-DO">es-DO</option>
      <option value="es-CL">es-CL</option>
      <option value="es-UY">es-UY</option>
      <option value="es-NI">es-NI</option>
      <option value="es-MX">es-MX</option>
      <option value="es-VE">es-VE</option>
    </Form.Control>
  )
}
export default SelectCurrencyLang