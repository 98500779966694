import * as types from './actionTypes'
import { Auth } from 'aws-amplify'
import { setCache } from '../helpers/cache'

export const authenticated = (user) => ({
  type: types.AUTHENTICATED,
  payload: { user },
})
export const unauthenticated = () => ({
  type: types.UNAUTHENTICATED,
})
export const authenticatedError = (error) => ({
  type: types.AUTHENTICATION_ERROR,
  payload: { error },
})
export const fetchUser = (e, navigate) => {
  e.preventDefault()
  const data = new FormData(e.target)
  const email = data.get('email')
  const pass = data.get('password')
  return (dispatch) =>
    Auth.signIn(email, pass)
      .then((data) => dispatch(authenticated(data.username)))
      .then((action) => login(action, navigate))
      .catch((error) => dispatch(authenticatedError(error)))
}
export const login = (action, navigate) => {
  console.log('login', action.payload.user)
  setCache('user', action.payload.user)
  navigate('/')
}
