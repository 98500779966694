import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Auth } from 'aws-amplify'
import { authenticated } from '../actions/auth'
import { setCache } from '../helpers/cache'

const Redirect = ({ history }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  useEffect(() => {
    Auth.currentUserInfo().then(data => {
      try {
        const user = data.username.split('_').pop()
        const roles = data.attributes['custom:user_roles']
        dispatch(authenticated(user))
        setCache('user', user)
        setCache('roles', roles)
        navigate('/')
      } catch(err) {
        navigate('/login')
      }
    })
  }, [dispatch, navigate])
}
export default Redirect
