import React from 'react'
import { Modal, Button } from 'react-bootstrap'

const ModalDelete = ({ id, show, onConfirm, onClose }) => {
  return (
    <Modal show={show} size="sm" onHide={onClose}>
      <Modal.Body>¿Estás seguro de eliminar?</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Cancelar
        </Button>
        <Button variant="delete" onClick={onConfirm}>
          Eliminar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
export default ModalDelete