import { API } from 'aws-amplify'
import * as types from './actionTypes'

export const fetchCredentialBegin = () => ({
  type: types.FETCH_CREDENTIAL_BEGIN
})
export const fetchCredentialList = items => ({
  type: types.FETCH_CREDENTIAL_LIST,
  payload: { items }
})
export const fetchCredentialFailure = error => ({
  type: types.FETCH_CREDENTIAL_FAILURE,
  payload: { error }
})
export const fetchCredentials = id => {
  return dispatch => {
    dispatch(fetchCredentialBegin())
    return API.get('admin', `/credentials/${id}`)
      .then(data => dispatch(fetchCredentialList(data.Items)))
      .catch(err => dispatch(fetchCredentialFailure(err)))
  }
}
export const createCredentials = data => {
  const params = { body: data }
  return dispatch => {
    dispatch(fetchCredentialBegin())
    return API.post('admin', `/credentials`, params)
      .then(() => dispatch(fetchCredentials(data.universityId)))
      .catch(err => dispatch(fetchCredentialFailure(err)))
  }
}
export const deleteCredentials = data => {
  const params = { body: data }
  return dispatch => {
    dispatch(fetchCredentialBegin())
    return API.del('admin', `/credentials`, params)
      .then(() => dispatch(fetchCredentials(data.universityId)))
      .catch(err => dispatch(fetchCredentialFailure(err)))
  }
}
