import React from 'react'
import { Form, Button, Card, Accordion } from 'react-bootstrap'

const AuthenticationLdap = ({ id, authParams, authHost }) => (
  <Card>
    <Accordion.Item eventKey={id}>
      <Card.Header>
        <Accordion.Button as={Button}variant="block text-start">
          LDAP
        </Accordion.Button>
      </Card.Header>
      <Accordion.Body>
        <Card.Body>
          <Form.Group controlId="authParams">
            <Form.Label>Parámetros Autenticación</Form.Label>
            <Form.Control
              name="authParams"
              type="text"
              defaultValue={authParams}
            />
          </Form.Group>
          <Form.Group controlId="authHost">
            <Form.Label>Host Autenticación</Form.Label>
            <Form.Control
              name="authHost"
              type="text"
              defaultValue={authHost}
            />
          </Form.Group>
        </Card.Body>
      </Accordion.Body>
    </Accordion.Item>
  </Card>
)
export default AuthenticationLdap