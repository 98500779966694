export const apiUrl = process.env.REACT_APP_API_URL
export const awsConfig = {
    apiGateway: {
        REGION: process.env.REACT_APP_API_REGION,
        URL: process.env.REACT_APP_API_URL,
    },
    cognito: {
        REGION: process.env.REACT_APP_COGNITO_REGION,
        USER_POOL_ID: process.env.REACT_APP_COGNITO_USER_POOL_ID,
        APP_CLIENT_ID: process.env.REACT_APP_COGNITO_CLIENT_ID,
        IDENTITY_POOL_ID: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
        DOMAIN: process.env.REACT_APP_COGNITO_DOMAIN,
        REDIRECT_SIGN_IN: process.env.REACT_APP_COGNITO_REDIRECT_SIGN_IN,
        REDIRECT_SIGN_OUT: process.env.REACT_APP_COGNITO_REDIRECT_SIGN_OUT
    }
}