import React from 'react'
import AcademicItem from './items/academicItem'
import EconomicItem from './items/economicItem'
import InvestigationItem from './items/investigationItem'
import HumanResourceItem from './items/humanResourceItem'
import SecureTitleItem from './items/secureTitleItem'
import GeneralItem from './items/generalItem'
import ServiceItem from './items/serviceItem'
import DigitalCardItem from './items/digitalCardItem'

const GroupItem = ({ identity, ...props }) => {
  const { item } = props
  switch (item.identity || identity) {
    case 'academic':
      return <AcademicItem {...props} />
    case 'economic':
      return <EconomicItem {...props} />
    case 'investigation':
      return <InvestigationItem {...props} />
    case 'humanresource':
      return <HumanResourceItem {...props} />
    case 'securetitle':
      return <SecureTitleItem {...props} />
    case 'general':
      return <GeneralItem {...props} />
    case 'service':
      return <ServiceItem {...props} />
    case 'digitalcard':
      return <DigitalCardItem {...props} />
    default:
      return <div>Default!</div>
  }
}
export default GroupItem