import React, { useState, useEffect } from 'react'
import { Form, Row, Col, Button } from 'react-bootstrap'
import SelectIdentity from '../../widgets/selectIdentity'
import SelectSystem from '../../widgets/selectSystem'
import SelectCache from '../../widgets/selectCache'
import ModalUCMap from '../../widgets/modalUCMap'
import { getFormValue } from '../../../helpers/formdata'
import { normalize } from '../../../helpers/utils'
import { endpoint } from '../../../helpers/utils'

const DigitalCardItem = ({ item, data, enabled }) => {
  const [code, setCode] = useState(null)
  const [url, setUrl] = useState(null)
  const [show, setShow] = useState(false)
  const handleClose = () => { setShow(false) }
  useEffect(() => {
    setCode(normalize(item.generalId))
    setUrl(endpoint(item.generalId))
  }, [item])
  if (!code) { return false }
  return (<>
    <Row>
        <Form.Check
          name={`general-${code}-enabled`}
          checked={enabled}
          className="visually-hidden"
          onChange={()=>(false)}
          type="checkbox"
        />
      <Col>
        <Form.Group controlId={`general-${code}-url`}>
          <Form.Label>URL {item.name.toLowerCase()}</Form.Label>
          <Form.Control
            name={`general-${code}-url`}
            defaultValue={getFormValue(data, code, 'url')}
            type="text"
          />
          <Form.Text className="text-muted">AWS {url}</Form.Text>
        </Form.Group>
      </Col>
      <Col md={2}>
        <Form.Group controlId={`general-${code}-identity`}>
          <Form.Label>Identidad</Form.Label>
          <SelectIdentity
            name={`general-${code}-identity`}
            value={getFormValue(data, code, 'identity')}
          />
        </Form.Group>
      </Col>
      <Col md={2}>
        <Form.Group controlId={`general-${code}-system`}>
          <Form.Label>Sistema fuente</Form.Label>
          <SelectSystem
            name={`general-${code}-system`}
            value={getFormValue(data, code, 'system')}
            defaultValue="default"
          />
        </Form.Group>
      </Col>
      <Col md={2}>
        <Form.Group controlId={`general-${code}-cache`}>
          <Form.Label>Caché</Form.Label>
          <SelectCache
            name={`general-${code}-cache`}
            value={getFormValue(data, code, 'cache')}
          />
        </Form.Group>
      </Col>
      <Col md={1}>
        <Button variant="link" className="btn-map" onClick={() => setShow(true)}>Mapeo</Button>
        <ModalUCMap
          title={item.name}
          code={code}
          data={data}
          show={show}
          onClose={handleClose}
        />
      </Col>
    </Row>
  </>)
}
export default DigitalCardItem