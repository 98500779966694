import { useEffect } from 'react'
import { Auth } from 'aws-amplify'

const LoginSaml = () => {
  useEffect(() => {
    const stage = process.env.REACT_APP_STAGE
    const provider = `${stage}-admin-panel-idp`
    Auth.federatedSignIn({
      provider: provider
    })
  }, [])
}
export default LoginSaml